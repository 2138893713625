import React from 'react';

const Input = (props) => {
    let text;

    switch (props.type) {
        case 2:
            text = "Your image URL";
            break;
        case 3:
            text = "Your YouTube video URL";
            break;
        case 4:
            text = "Your audio URL";
            break;
        default:
            text = "Your text";
    }

    return (
        <input data-for={props.for} data-num={props.num} type={props.type === 1 ? "text" : "url"} maxLength={props.type === 1 ? 60 : 300} placeholder={text} onChange={props.changed} value={props.content}></input>
    )
}

export default Input;
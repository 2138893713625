import { appVersion } from "./appVersion";

export const updateCards = (col) => {
    col.cards = col.cards.map(card => {
        return {
            id: card.id,
            content: {
                1: {
                    content: card.text ? card.text : card.content[1].content,
                    contentType: 1,
                },
                2: {
                    content: card.imgURL ? card.imgURL : card.content[2].content,
                    contentType: 2
                },
            }
        }
    })
    col.appVersion = appVersion();
        
    return col;
}


import React, { useState } from 'react';
import Teams from '../Teams/Teams';
import './CreateGameMenu.css';
import MenuCustomCollectionItem from '../MenuCustomCollectionItems/MenuCustomCollectionItem/MenuCustomCollectionItem';

const CreateGameMenu = props => {
    const [disabled, setDisabled] = useState(props.mode === 'local' ? false : true);
    // console.log(props.mode);

    function handleRadioClick(mode, state) {
        setDisabled(state);
        props.setMode(mode);
    }

    return (
        <div className="create-game">
            {props.collection ?
            <>
            <p className="create-game-title">{props.collection.title}</p>
            <ul className="create-game-cards">
                {props.collection.cards ? 
                    <MenuCustomCollectionItem
                        cards={props.collection.cards}
                    />
                :
                <p className="create-game-title">It seems there are no cards...</p>
                }
            </ul>
            </>
            : <p>Please enter an ID.</p>}
            {props.join ? 
                <input id="game-id" type="text" placeholder={'Game ID'} onChange={props.handleGameID}></input>
                : null
            }
            <div className="create-game-box">
                {!props.join ? <button className="add-team-btn" disabled={disabled} onClick={props.addTeam}>
                    <ion-icon name="person-add-outline"></ion-icon>
                    <small>add a team</small>
                </button>:null}
                    <Teams 
                        teams={disabled ? [props.teams[0]] : props.teams}
                        editTeamName={props.editTeamName}
                        deleteTeam={props.deleteTeam}
                        updateTeams={props.updateTeams}
                        language={props.language}
                        mode={props.join ? 'join' : props.mode}
                    />
            </div>
            {!props.join ? 
                <>
                    <div className="create-game-options">
                        <p>Mode</p>
                        <div className='grow'>
                            <input onChange={() => handleRadioClick('host', true)} type="radio" value="host" id="online" name="Game type" checked={props.mode === 'host' ? true : false}></input>
                            <label htmlFor="online">Online</label>
                            <input onChange={() => handleRadioClick('local', false)} type="radio" value="local" id="local" name="Game type" checked={props.mode === 'local' ? true : false}></input>
                            <label htmlFor="local">Local</label>
                        </div>
                    </div>
                    <div className="create-game-options">
                        <p>flipAttacks</p>
                        <div className='grow'>
                            <input type="checkbox" id="shuffle" onChange={props.handlePowerUpCheck} defaultChecked></input>
                            <label htmlFor="shuffle">Shuffle</label>
                            <input type="checkbox" id="skip" onChange={props.handlePowerUpCheck} defaultChecked></input>
                            <label htmlFor="skip">Skip</label>
                        </div>
                        
                    </div>
                    <div className="create-game-options">
                        <p>Options</p>
                        <div className='grow'>
                            <div className="options-inner-div">
                                <div className="label-group">
                                <input type="checkbox" id="autoskip" onChange={props.handleOptionCheck} defaultChecked></input>
                                <label htmlFor="autoskip">Autoskip media after set time</label>
                                </div>
                                <small>Recommended for those using old browsers or on slow connections.</small>
                                <div className="label-group">
                                    <input type="number" min={0} id="autoskip_time" onChange={props.handleOptionChange} defaultValue={props.options.autoskip.time}></input>
                                    <small>seconds</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            : null}
        </div>
    )
}

export default CreateGameMenu;
import React from 'react';
import InfoPage from "../InfoPage/InfoPage";

const Contact = props => {
    return (
        <InfoPage
            title={"Contact"}        
        >
            <div>
                <p>Are you having issues or looking to request features? Contact us!</p>
                <p>contact@flipget.app</p>
            </div>
        </InfoPage>
        
    )
}

export default Contact;
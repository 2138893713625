import React from "react";
import BoardTopbar from './BoardTopbar/BoardTopbar';
import Card from "../Card/Card";
import './Board.css';
import AttackMenu from "./AttackMenu/AttackMenu";
import styled from 'styled-components';
import LoadingIndicator from '../../hoc/LoadingIndicator';
import { CSSTransition } from 'react-transition-group';
import { Button } from "react-bootstrap";
import Results from './Results/Results';
import SuccessMessage from '../SuccessMessage/SuccessMessage';
import ModalMessage from '../../hoc/ModalMessage/ModalMessage';
import uniqid from 'uniqid';
import { Redirect } from 'react-router-dom';
import simpleShareID from "../../functions/simpleShareID";
import {buildCard, buildTeam} from "../../functions/board";
import { appVersion } from "../../functions/appVersion";


//TODO: revert all functions calls to props.db, remove functions from package, change cards to match teams array and teams object

class Board extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      game: {
        teams: {},
        playOrder: [],
        decks: {},
        firstCard: null,
        isFirstFlip: true,
        message: 'Welcome!',
        // pendingWrites: false,
        results: null
      },
      status: 'playing',
      loading: {
        page: true,
        game: false
      },
      attackMenu: {
        display: false,
        type: null
      },
      cardWidth: 150,
    }
  }

  async componentDidMount() {

    window.addEventListener("beforeunload", this.onUnload);

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      this.openFullscreen();
    }

    if (this.props.mode === 'host' || window.location.pathname === '/share') {
      await this.setOnlineGameCode();
    }
    this.initialize();
  }

  async componentDidUpdate(prevProps, prevState) {
    if (this.state.game.message && this.state.game.message !== prevState.game.message) {
      this.setSuccess(this.state.game.message, this.state.game.messageInfo)
    }

    if (this.props.page === 'Board' && prevProps.page !== this.props.page) {
      this.initialize();
    }
  }

  async componentWillUnmount() {
    // console.log('unmounting');
    if (this.state.leaveGameTimeout) clearTimeout(this.state.leaveGameTimeout);
    window.removeEventListener("beforeunload", this.onUnload);
  }

  openFullscreen() {
    const elem = document.documentElement;

    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) { /* Firefox */
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) { /* IE/Edge */
      elem.msRequestFullscreen();
    }
  }

  onUnload = e => {
    e.preventDefault();
    e.returnValue = '';
  }

  //SETUP

  initialize = async (reset) => {
    if (!this.state.loading.page) this.toggleLoading('page', true);
    const mode = this.props.mode;

    if (!this.props.id) {
      return this.handleLoadFromBookmark();
    }

    if (mode === 'host') {

      await this.setCardCollection(this.props.id);

      const game = this.setTeams(reset);

      await this.createOnlineGame(game);

    } else if (mode === 'join') {

      let ID = this.props.id;

      const team = this.props.teams[0];

      const game = { ...this.state.game }

      game.id = this.props.id;

      this.setState({
        localID: team.id,
        game: game
      });

      await this.joinOnlineGame(ID, team);
    } else {

      //setup board
      await this.setCardCollection(this.props.id);

      //setup teams
      const game = this.setTeams();

      //final game setup
      this.createLocalGame(game);
    }

    // console.log(this.state);
  }

  setTeams = (reset) => {
    const game = { ...this.state.game };
    const teams = {};

    if (this.props.mode === 'local') {
      this.props.teams.map(team => {
        return teams[team.id] = buildTeam(team.name, team.id, this.props.flipAttacks);
      });
    } else {
      if (reset) {
        Object.keys(game.teams).map(teamKey => {
          return teams[teamKey] = buildTeam(game.teams[teamKey].name, game.teams[teamKey].id, game.flipAttacks);
        })
      } else {
        teams[this.props.teams[0].id] = buildTeam(this.props.teams[0].name, this.props.teams[0].id, this.props.flipAttacks);
      }
    }

    game.teams = teams;
    game.playOrder = Object.keys(teams).reduce((acc, key) => {
      acc.push(key);
      return acc;
    }, []);

    return game;
  }

  setCardCollection = async id => {
    let selectedCloudSave, cards;
    // console.log(this.props);

    if (window.location.pathname === '/share') {
      //get card data from firebase share collection
      selectedCloudSave = await this.props.getFirebaseDocument(id, 'share');
      selectedCloudSave = selectedCloudSave.appVersion >= appVersion() ? selectedCloudSave : await this.props.september21UpdateCheck(selectedCloudSave);
      cards = [...selectedCloudSave.cards];
    } else {
      //pull card data from local data
      const cloudSaves = [...this.props.user.cloudSaves];
      // console.log({cloudSaves});
      selectedCloudSave = cloudSaves.find(c => c.id === id);
      // console.log(selectedCloudSave);
      cards = [...selectedCloudSave.cards];
    }

    const decks = {
      og: {
        cards: {},
        cardOrder: []
      }
    };

    //Add the shuffle power-up card

    if (this.props.flipAttacks.shuffle) {
      const key = uniqid();
      decks.og.cards[key] = buildCard("shuffle-outline.svg", 2, "shuffle", key);
      decks.og.cardOrder.push(key);
    }

    //destructure cards into text and image pairs

    cards.map((card) => {
      return Object.keys(card.content).map(num => {
        const key = uniqid();
        decks.og.cardOrder.push(key);
        return decks.og.cards[key] = buildCard(card.content[num].content, card.content[num].contentType, card.id, key);
      });
    });

    decks.og.cardOrder = [...this.shuffleCards(decks.og.cardOrder)];

    decks.og.cardOrder.map((key) => {
      return this.cacheImage(decks.og.cards[key]);
    });

    const randAppear = this.getRandomAppearNumber(decks.og.cardOrder);

    const game = { ...this.state.game }

    game.title = selectedCloudSave.title;
    game.randAppear = randAppear;
    game.decks = decks;
    game.structuredCards = selectedCloudSave.cards;

    return this.setState({
      game: game,
    });
  }

  getRandomAppearNumber = cards => {
    let randAppearArr = [];

    let x = cards.length;

    do {
      randAppearArr.push(Math.random());
      x -= 1;
    } while (x > 0)

    return randAppearArr;
  }

  shuffleCards(originalCardArray) {
    let shuffledCardArray = [];
    Object.assign(shuffledCardArray, originalCardArray);
    for (let i = 0; i < shuffledCardArray.length; i++) {
      let temp = shuffledCardArray[i];
      let swapIndex = Math.floor(Math.random() * shuffledCardArray.length);
      shuffledCardArray[i] = shuffledCardArray[swapIndex];
      shuffledCardArray[swapIndex] = temp;
    }
    return shuffledCardArray;
  }

  debounce(func, timeout = 300) {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => { func.apply(this, args); }, timeout);
    };
  }

  cacheImage = async (src) => {
    await new Promise(async (resolve, reject) => {
      const img = new Image();

      img.src = src;

      // console.log('saved image');
    });

    return;
  }

  createLocalGame = (game) => {
    //final game setup
    game.activeID = game.playOrder[0];
    game.firstCard = null;
    game.isFirstFlip = true;
    game.results = null;
    game.options = this.props.options;

    this.setState({
      game: game,
      status: 'playing'
    });

    this.toggleLoading('page', false);
  }

  joinOnlineGame = async (id, team) => {

    const game = await this.props.getFirebaseDocument(id, 'games');

    this.setState({ game: game });

    const newTeam = buildTeam(team.name, team.id, game.flipAttacks);

    const update = {
      "playOrder": this.props.fire.FieldValue.arrayUnion(newTeam.id),
      "message": 'game-teamJoined',
    }

    update[`teams.${newTeam.id}`] = newTeam;

    await this.updateDatabaseObject(update);
    await this.listenForOnlineGameChanges(id);
    // console.log('continuing to page load toggle');
    if (this.state.loading.page) return this.toggleLoading('page');
  }

  async setOnlineGameCode() {
    let gameID;
    do {
      gameID = simpleShareID();
    } while (await this.props.exists('games', gameID));

    // console.log('game code set to', gameID);

    return this.setState({sessionGameCode: gameID});
  }

  createOnlineGame = async (game) => {

    const team = this.props.teams[0];

    const localID = team.id;

    this.setState({
      localID: localID,
    });


    //set game object properties

    game.id = this.state.sessionGameCode;
    game.activeID = game.teams[game.playOrder[0]].id;
    game.flipAttacks = this.props.flipAttacks;
    game.firstCard = null;
    game.isFirstFlip = true;
    game.results = null;
    game.options = this.props.options;

    this.setState({
      game: game,
      status: 'playing'
    });

    //set in firebase
    this.props.set('games', game);

    //listen to database object
    this.listenForOnlineGameChanges(game.id);

    //toggle loading
    this.toggleLoading('page', false);
  }

  handleLoadFromBookmark = async () => {
    const id = window.location.hash.replace('#', '');
    // console.log(window.location.pathname);
    //display special game creation modal
    if (id) {
      this.props.handleOpenMenu(id, `${window.location.pathname}#${id}`, window.location.pathname);
    } else {
      // console.log('hey! we couldnt find that');
    }
  }

  //FIREBASE

  updateDatabaseObject = async (update) => {

    const id = this.state.game.id;

    const ref = this.props.db.collection(`games`).doc(id);

    try {
      return await ref.update(update);
      // console.log(2, 'sent a new update!', update);
    } catch (error) {
      if (error.message === 'Failed to get document because the client is offline.') {
        this.props.error('auth/network-request-failed');
      } else {

        this.props.error('game-noGameFound');
        clearTimeout(this.state.leaveGameTimeout);
        // console.log(error, 'error! returning to menu');
        this.props.setPage('Menu');
      }
    }
  }

  listenForOnlineGameChanges = async id => {
    const ref = this.props.db.collection(`games`).doc(id);

    // console.log(id);

    let unsubscribe = ref.onSnapshot(async rawDoc => {

      if (rawDoc.exists) {

        const doc = rawDoc.data();

        if (this.isKicked(doc)) {
          await this.state.unsubscribe();
          return this.setState({ redirect: true });
        } else {
          if (doc.activeID !== this.state.game.activeID) {
            const el = document.querySelector(`#team-${doc.activeID}`);
            if (el) {
              el.scrollIntoView();
            }
          }
          if (this.props.mode !== 'local' && doc.activeID === this.state.localID && doc.teams[this.state.localID].shouldSkip > 0 && this.state.status === 'playing') {
            // console.log('going to resolve a skip');
            this.setState({ status: 'resolvingSkip' });
            return this.resolveSkip();
          }
          return this.setState({ game: doc });
        }
      }
    });
    if (!this.state.unsubscribe) return this.setState({ unsubscribe: unsubscribe });
  }

  isKicked = doc => {
    const self = this.state.localID;

    //check if self is kicked

    if (!doc.playOrder.includes(self)) {
      this.setSuccess('game-kicked');

      return true;
    }

    return false;
  }

  //GAME FUNCTIONS

  flipController = async (key) => {

    // console.log('flipCard called');

    let gameObj, teams, mode, activeID, localID, cards, isFirstFlip, card;

    gameObj = { ...this.state.game };

    const postMatch = 1000;
    const next = 3000;
    const again = 2000;
    const postMiss = 1000;
    const unflip = 2500;
    const gameOver = 2000;

    const postMatchOnline = 1000;
    const nextOnline = 3000;
    const postMissOnline = 1000;
    const unflipOnline = 2500;
    // const gameOverOnline = 200;

    teams = gameObj.teams;
    mode = this.props.mode;
    activeID = gameObj.activeID;
    localID = this.state.localID;
    cards = gameObj.decks.og.cards;
    isFirstFlip = gameObj.isFirstFlip;
    card = cards[key];

    //make sure player is allowed to flip
    if (!this.isAbleToFlip(mode, activeID, localID, teams, this.state.status)) {
      return;
    }

    //disallow clicks
    this.setFlippingStatus();

    //set flipped property to true
    await this.flipCard(key);

    //if the player flipped the shuffle powerup card
    if (card.matchID === 'shuffle') {
      //give player the ability to use a shuffle
      return await this.handleShuffleFlip(mode, key, localID, card, gameObj);
    }

    if (isFirstFlip) {

      const f = () => this.setFirstFlip(mode, key, gameObj, card);

      this.postFlipDelayForMedia(card, f);

    } else {

      const firstCard = cards[this.state.game.firstCard];

      if (card.matchID === firstCard.matchID) {
        const matchedCard = this.state.game.structuredCards.find(c => c.id === card.matchID);
        if (mode !== 'local') {
          this.sendMatchUpdate(card, firstCard, matchedCard, localID);
          return this.handlePostMatchOnline(postMatchOnline);
        } else {
          this.handleMatchLocal(card, firstCard, matchedCard, gameObj, postMatch);
          this.addPoints();
        };
        this.handlePostMatchLocal(gameObj, gameOver, again);
      } else {
        const f = () => this.handleMismatch(card, postMissOnline, unflipOnline, nextOnline, postMiss, unflip, next, mode);
        this.postFlipDelayForMedia(card, f);
      };
    };
  }

  handleMismatch = (card, postMissOnline, unflipOnline, nextOnline, postMiss, unflip, next, mode) => {
    if (mode !== 'local') {
      this.handleMismatchOnline(postMissOnline, unflipOnline, nextOnline);
    } else {
      this.handleMismatchLocal(postMiss, unflip, next);
    }
  }

  postFlipDelayForMedia = (card, f) => {
    if (card.type === 4) {
      const el = document.querySelector(`[data-key='${card.key}']`);
      // console.log(el.readyState);
      if ((!el.ended && !el.paused) || el.readyState < 4) {
        // const f = () => this.handleMismatch(card, postMissOnline, unflipOnline, nextOnline, postMiss, unflip, next, mode);
        if (this.state.game.options.autoskip.isOn) {
          const timeout = setTimeout(() => {
            // console.log('im the timeout');
            return this.postMediaPlayCleanup();
          }, this.state.game.options.autoskip.time * 1000);
          this.setState({
            delayedPostMediaTimeout: timeout
          });
        };
        this.setState({
          delayedFunction: f,
          delayedEl: el,
        });
        el.addEventListener('pause', this.postMediaPlayCleanup);
        return el.addEventListener('ended', this.postMediaPlayCleanup);
      }
    } else {
      f();
    }
  }

  postMediaPlayCleanup = () => {
    if (!this.state.delayedEl || !this.state.delayedFunction) return;
    clearTimeout(this.state.delayedPostMediaTimeout);
    this.state.delayedEl.removeEventListener('pause', this.postMediaPlayCleanup);
    this.state.delayedEl.removeEventListener('ended', this.postMediaPlayCleanup);
    // const duration = el.duration * 1000;
    this.state.delayedFunction();
    return this.setState({
      delayedEl: null,
      delayedFunction: null,
      delayedTimeout: null
    });
  }

  handleMismatchLocal = (postMissTime, unflipTime, nextTime) => {
    const gameObj = {...this.state.game};
    gameObj.message = 'game-mismatch';
    setTimeout(() => this.setState({ game: gameObj }), postMissTime);
    setTimeout(() => this.setCurrentTeam(), nextTime);
    setTimeout(() => this.flipUnmatchedCards(gameObj), unflipTime);
  }

  handleMismatchOnline = (postMissTime, unflipTime, nextTime) => {
    setTimeout(async () => await this.updateDatabaseObject({"message": 'game-mismatch' }), postMissTime);
    setTimeout(async () => {
      await this.updateDatabaseObject({ "pendingWrites": true });
      await this.setCurrentTeam();
      await this.updateDatabaseObject({ "pendingWrites": false});
      return this.setState({ status: 'playing' });
    }, nextTime);
    setTimeout(() => this.flipUnmatchedCards(this.state.game), unflipTime);
  }

  handlePostMatchLocal = (gameObj, gameOverTime, time) => {
    if (this.isGameOver()) {

      return setTimeout(() => this.displayGameResults(), gameOverTime);

    } else {
        gameObj.message = 'game-goAgain';
        gameObj.isFirstFlip = true;
        gameObj.firstCard = null;
        return setTimeout(() => this.setState({
          game: gameObj,
          status: 'playing'
        }), time);
    }
  }

  handleMatchLocal = (card, firstCard, matchedCard, gameObj, time) => {
    gameObj.message = 'game-match';
    const savedGameObj = { ...gameObj };
    savedGameObj.teams[savedGameObj.activeID].matchedCards.push(matchedCard);
    this.setCardStatus(card.key, 3);
    this.setCardStatus(firstCard.key, 3);
    setTimeout(() => {
      this.setCardDisplayStatus(card.key, 4);
      this.setCardDisplayStatus(firstCard.key, 4);
      this.setState({ game: savedGameObj });
    }, time);
  }

  handlePostMatchOnline = async (time) => {
    return setTimeout(async () => {
      await Promise.all([
        this.updateDatabaseObject({ "message": 'game-match' }),
      ]);
      if (this.isGameOver()) {
        
        this.setState({status: 'playing'});
        return this.displayGameResults();
      }
      return this.handleOnlineGameGoAgain();
    }, time);
  }

  sendMatchUpdate = async (card, firstCard, matchedCard, localID) => {
    const update = {};
    await this.setCardStatus(card.key, 3);
    await this.setCardStatus(firstCard.key, 3);
    await this.setCardDisplayStatus(card.key, 4);
    await this.setCardDisplayStatus(firstCard.key, 4);
    update[`teams.${localID}.points`] = this.props.fire.FieldValue.increment(1);
    update[`teams.${localID}.matchedCards`] = this.props.fire.FieldValue.arrayUnion(matchedCard);

    return await this.updateDatabaseObject(update);
  }

  setFirstFlip = async (mode, key, gameObj, card) => {    
    if (mode !== 'local') {
      const update = {
        "firstCard": key,
        "isFirstFlip": false
      };

      await this.updateDatabaseObject(update);
      return this.setState({ status: 'playing' });
    } else {
      gameObj.isFirstFlip = false;
      gameObj.firstCard = card.key;
      return this.setState({
        game: { ...gameObj },
        status: 'playing'
      });
    }
  }

  handleShuffleFlip = async (mode, key, localID, card, gameObj) => {
    this.setCardDisplayStatus(key, 4);
    this.setCardStatus(key, 3);
    if (mode !== 'local') {
      const update = {};
      update[`teams.${localID}.flipAttacks.shuffle`] = true;

      await this.updateDatabaseObject(update);
      return this.setState({ status: 'playing' });
    } else {
      return this.setShuffleAbility(gameObj);
    }
  }

  isAbleToFlip = (mode, activeID, localID, teams, status) => {
    if ((mode !== 'local' && (activeID !== localID || teams[localID].shouldSkip)) || status !== 'playing') return false;

    return true;
  }

  flipCard = async (key) => {
    await this.setCardStatus(key, 2);
    return await this.setCardDisplayStatus(key, 2);
  }

  handleOnlineGameGoAgain = () => {
    const againOnline = 1000;
    
    const update = {
      "isFirstFlip": true,
      "firstCard": this.props.fire.FieldValue.delete(),
      "message": 'game-goAgain'
    }

    return setTimeout(async () => {
      await this.updateDatabaseObject(update);
      return this.setState({ status: 'playing' });
    }, againOnline)
  }

  flipUnmatchedCards = () => {
    Object.keys(this.state.game.decks.og.cards).map(key => {
      // console.log(this.state.game.decks.og.cards[key].status);
      if (this.state.game.decks.og.cards[key].status === 2) {
        this.setCardStatus(key, 1);
        this.setCardDisplayStatus(key, 1);
      };
      // console.log(update);
      return null;
    });
  }

  setFlippingStatus = () => {
    this.setState({ status: 'flipping' });
  }

  setShuffleAbility = game => {
    const team = game.teams[game.activeID];

    team.flipAttacks.shuffle = true;

    if (this.props.mode !== 'local') {
      return this.updateDatabaseObject(game);
    } else {
      return this.setState({
        game: game,
        status: 'playing'
      });
    }
  }

  resolveSkip = async () => {
    const skipDelay = 2000;

    await this.updateDatabaseObject({"pendingWrites": true });
    const update = {
      "message": 'game-skip'
    };
    update[`teams.${this.state.localID}.shouldSkip`] = this.props.fire.FieldValue.increment(-1);
    await this.updateDatabaseObject(update);
    setTimeout(async () => {
      // console.log('changing teams to resolve skip')
      await Promise.all([
        this.setCurrentTeam(),
        this.flipUnmatchedCards(this.state.game)
      ]);

      // console.log('setting status to playing in resolve skip');
      this.setState({
        status: 'playing'
      });
      return await this.updateDatabaseObject({ "pendingWrites": false });
    }, skipDelay);
  }

  setCurrentTeam = async () => {
    // console.log('changing teams');
    this.setState({status: 'changingTeams'});
    const gameObj = { ...this.state.game };
    const mode = this.props.mode
    const curActiveID = gameObj.activeID;
    const order = gameObj.playOrder;
    const teams = gameObj.teams;

    const curTeamInd = order.findIndex(id => {
      return id === curActiveID
    });

    const next = order[curTeamInd + 1] ? curTeamInd + 1 : 0;

    gameObj.activeID = order[next];

    if (mode !== 'local') {
      const update = {
        "isFirstFlip": true,
        "firstCard": this.props.fire.FieldValue.delete(),
        "activeID": order[next],
        "message": 'game-next'
      };
      await this.updateDatabaseObject(update);
      // console.log('setting status to playing');
      return this.setState({status: 'playing'});
    }

    gameObj.isFirstFlip = true;
    gameObj.firstCard = {};

    gameObj.message = teams[order[next]].shouldSkip > 0 ? 'game-skip' : 'game-next';
    if (teams[order[next]].shouldSkip) {
      // console.log('skipping')
      gameObj.teams[order[next]].shouldSkip -= 1;
      this.setState({game: gameObj});
      return setTimeout(() => this.setCurrentTeam(), 2000);
    }
    this.setState({
      game: gameObj,
      status: 'playing'
    });

    const team = document.querySelector(`#team-${gameObj.activeID}`);
    if (!team) {
      return;
    }
    
    team.scrollIntoView();
  }

  addPoints = () => {

    const gameObj = { ...this.state.game };
    const team = gameObj.teams[gameObj.activeID];

    team.points += 1;

    this.setState({ game: gameObj });
  }

  addMatchedCards = (firstCard, card) => {
    const gameObj = { ...this.state.game };
    const mode = this.props.mode;
    const team = gameObj.teams[gameObj.activeID];

    if (firstCard.text) {
      team.matchedCards.push({
        text: firstCard.text,
        imgURL: card.imgURL
      });
    } else {
      team.matchedCards.push({
        text: card.text,
        imgURL: firstCard.imgURL
      });
    };

    if (mode !== 'local') {
      this.updateDatabaseObject(gameObj);
    } else {
      this.setState({ game: gameObj });
    }
  }

  setCardDisplayStatus = async (id, status) => {
    if (this.props.mode !== 'local') {
      const update = {};
      update[`decks.og.cards.${id}.displayStatus`] = status;
      return await this.updateDatabaseObject(update);
    } else {
      const gameObj = { ...this.state.game };
      const card = gameObj.decks.og.cards[id];
      // console.log(`setting ${id} to`, status);
      card.displayStatus = status;
      this.setState({ game: gameObj });
    }
  }

  setCardStatus = async (id, status) => {
    if (this.props.mode !== 'local') {
      const update = {};
      update[`decks.og.cards.${id}.status`] = status;
      return await this.updateDatabaseObject(update);
    } else {
      const gameObj = { ...this.state.game };
      const card = gameObj.decks.og.cards[id];
      // console.log(`setting ${id} to`, status);
      card.status = status;
      this.setState({ game: gameObj });
    }
  }

  isGameOver = () => {
    const game = { ...this.state.game };

    // console.log('checking game over status');

    return !game.decks.og.cardOrder.some(key => {
      if (game.decks.og.cards[key].matchID === 'shuffle') return false;
      return game.decks.og.cards[key].status !== 3;
    })
  }

  reset = () => {
    // console.log(this.state);
    this.initialize(true);
  }

  leaveGame = async () => {

    // console.log('leave game called');

    const game = { ...this.state.game };
    const mode = this.props.mode;
    const id = game.id;

    if (mode === 'join') {

      this.toggleLoading('game', true);

      if (await this.isDatabaseObjectBusy(id)) {
        // console.log('database busy, setting timeout')
        const leaveGameTimeout = setTimeout(() => this.leaveGame(), 200);
        return this.setState({ leaveGameTimeout: leaveGameTimeout });
      }

      if (this.state.game.activeID === this.state.localID) {
        // console.log('current team is leaving, so first moving to next team');
        // await this.props.functions.httpsCallable('updatePendingWrites')({gameID: this.state.game.id, value: false});
        await Promise.all([
          this.flipUnmatchedCards(game),
          this.setCurrentTeam()
        ]);
      }

      await this.leaveOnlineGame(this.state.localID);
      this.toggleLoading('game', false);
    } else if (mode === 'host') {
      //Delete document from Firebase
      await this.props.deleteDocumentFromFirebase('games', id);
    }

    if (await this.props.exists('games', this.state.game.id)) await this.state.unsubscribe();
    this.setState({ redirect: true });
    return this.props.setPage('Menu');
  }

  isDatabaseObjectBusy = async (id) => {

    const ref = this.props.db.collection(`games`).doc(id);

    try {
      const doc = await ref.get();

      const pendingWrites = doc.data().pendingWrites;

      // console.log(pendingWrites ? 'database busy' : 'database free');
      return pendingWrites;
    } catch (error) {
      if (error.message === 'Failed to get document because the client is offline.') {
        this.props.error('auth/network-request-failed');
      } else {
        //TODO: refactor these to proper methods
        this.props.error('game-noGameFound');
        if (this.state.leaveGameTimeout) clearTimeout(this.state.leaveGameTimeout);
        return this.setState({ redirect: true });
      }
    }
  }

  leaveOnlineGame = async (id) => {
    const update = {
      "playOrder": this.props.fire.FieldValue.arrayRemove(id),
      "message": 'game-teamDisconnect'
    };

    update[`teams.${id}`] = this.props.fire.FieldValue.delete();

    return await this.updateDatabaseObject(update);
  }

  //GAME MENUS

  displayAttackMenu = type => {
    // console.log('called with type:', type);

    const attackMenu = { ...this.state.attackMenu }

    attackMenu.display = true;
    attackMenu.type = type;

    this.setState({ attackMenu: attackMenu });
  }

  resetAttackMenu = () => {
    const attackMenu = { ...this.state.attackMenu };

    attackMenu.display = false;
    attackMenu.type = null;

    this.setState({ attackMenu: attackMenu });
  }

  displayGameResults = () => {

    const gameObj = { ...this.state.game };
    const teams = gameObj.teams;
    const mode = this.props.mode;
    const results = [];

    Object.keys(teams).map(team => {
      return results.push({
        name: teams[team].name,
        points: teams[team].points
      });
    });

    results.sort((a, b) => (a.points > b.points) ? -1 : 1);

    gameObj.results = results;
    gameObj.message = 'game-over';

    // console.log('displaying results!');

    if (mode !== 'local') {
      // this.props.functions.httpsCallable('updatePendingWrites')({gameID: this.state.game.id, value: false});
      this.updateDatabaseObject(gameObj);
    } else {
      this.setState({ game: { ...gameObj } });
    }


  }

  //ATTACKS

  skipAttack = async id => {
    this.toggleLoading('game', true);

    const mode = this.props.mode;
    const gameObj = { ...this.state.game };
    const teams = gameObj.teams;
    const attacked = teams[id];
    const attacker = mode === 'local' ? teams[gameObj.activeID] : teams[this.state.localID];

    if (mode !== 'local') {
      if (await this.isDatabaseObjectBusy(this.state.game.id)) return setTimeout(() => this.skipAttack(id), 200);
      const update = {
        "message": 'game-skipAttack',
        "messageInfo": {
          attacker: attacker.name,
          attacked: attacked.name
        }
      };
      update[`teams.${attacked.id}.shouldSkip`] = this.props.fire.FieldValue.increment(1);
      update[`teams.${this.state.localID}.flipAttacks.skip`] = false;
      await this.updateDatabaseObject(update);
      return this.toggleLoading('game', false);
    }

    attacked.shouldSkip += 1;
    attacker.flipAttacks.skip = false;
    gameObj.message = 'game-skipAttack';
    gameObj.messageInfo = {
      attacker: attacker.name,
      attacked: attacked.name
    };

    if (mode !== 'local') {
      this.updateDatabaseObject(gameObj);
    } else {
      this.setState({ game: gameObj });
    }

    this.toggleLoading('game', false);
  }

  kickTeam = async id => {

    this.toggleLoading('game', true);
    await this.updateDatabaseObject({ "pendingWrites": true });
    //first, await move to next team
    if (id === this.state.game.activeID) {
      await Promise.all([
        this.flipUnmatchedCards(this.state.game),
        this.setCurrentTeam()
      ]);
    }
    await this.leaveOnlineGame(id);
    await this.updateDatabaseObject({ "pendingWrites": false });
    return this.toggleLoading('game', false);
  }

  shuffleAttack = async (attacked) => {

    //create a new deck and it to the variations
    const game = { ...this.state.game };
    const newDeck = this.shuffleCards(game.decks.og.cardOrder);
    const mode = this.props.mode;
    const newDeckID = uniqid();

    game.decks[newDeckID] = {};
    game.decks[newDeckID].cardOrder = newDeck;

    //update the attacked team's deck index and the state of attacker team
    const attackedTeam = game.teams[attacked];
    const attackerTeam = mode === 'local' ? this.state.game.teams[game.activeID] : this.state.game.teams[this.state.localID];

    if (mode !== 'local') {
      const update = {
        "message": 'game-shuffleAttack',
        "messageInfo": {
          attacker: attackerTeam.name,
          attacked: attackedTeam.name
        }
      };

      update[`decks.${newDeckID}.cardOrder`] = newDeck;
      update[`teams.${attackedTeam.id}.usingCards`] = newDeckID;
      update[`teams.${this.state.localID}.flipAttacks.shuffle`] = false;
      return await this.updateDatabaseObject(update);
    }


    attackedTeam.usingCards = newDeckID;
    attackerTeam.flipAttacks.shuffle = false;

    //set message info
    game.message = 'game-shuffleAttack';
    game.messageInfo = {
      attacker: attackerTeam.name,
      attacked: attackedTeam.name
    };

    if (mode !== 'local') {
      return this.updateDatabaseObject(game);
    } else {
      return this.setState({ game: game });
    }
  }

  //FEEDBACK

  setSuccess = (message, info) => {
    const successesArr = this.state.successes ? [...this.state.successes] : [];
    successesArr.push({
      success: message,
      successInfo: info
    })
    // console.log('set', {successesArr});
    this.setState({
      successes: successesArr
    });
  }

  resolveSuccess = () => {
    const successesArr = [...this.state.successes];
    successesArr.shift();
    // console.log('resolve', {successesArr});

    successesArr.length ? 

    this.setState({
      successes: successesArr
    })
    :
    this.setState({
      successes: null
    })
  }

  dismissSuccess = () => {
    if (!this.state.successes) return;
    
    this.resolveSuccess();
  }

  displayModal = (type, info, link) => {
    this.setState({
      modal: type,
      modalInfo: info,
      modalLink: link
    });
  }

  cancelModal = () => {
    // console.log('cancelling modal');

    this.setState({
      modal: null,
      modalInfo: null
    });
  }

  toggleLoading = (type, state) => {
    const loading = { ...this.state.loading };

    loading[type] = state;

    this.setState({ loading: loading })
  }

  //BROWSER FUNCTIONS

  handleBrowserButton = () => {
    const id = window.location.hash.replace('#', '');
    // console.log('board back button');
    // console.log(this.props.user);
    if (id) {
      this.setCardCollection(id);
    }
  }

  handleCardWidthChange = (e) => {
    this.setState({cardWidth: parseInt(e.target.value)})
  }

  render = () => {

    const game = this.state.game;

    const languageStrings = {

      en: {
        0: `Your game code: ${game.id}`,
        1: 'Back'
      },

      jp: {
        0: `あなたのオンラインゲームコード: ${game.id}`,
        1: '戻る'
      }
    }

    let lang = languageStrings[this.props.language];

    if (this.state.redirect) {
      return <Redirect to='/' />;
    }

    return (
      this.state.loading.page ?
        <div className={"board"}>
          <div className="board-button-box">
            <Button key={'return-button'} variant="danger" className="board-button"
              onClick={() => this.displayModal('back-button')}
            >
              <ion-icon name="chevron-back-outline"></ion-icon>
            </Button>
          </div>
          <LoadingIndicator />
          {this.state.modal ?
            <ModalMessage
              modal={this.state.modal}
              info={this.state.modalInfo}
              link={this.state.modalLink}
              leaveGame={this.leaveGame}
              language={this.props.language}
              loading={this.state.loading}
              cancelModal={this.cancelModal}
            />
            :
            null
          }
        </div>
        :
        <div key={'board'} className="board" onClick={() => this.dismissSuccess()}>
          {this.state.modal ?
            <ModalMessage
              modal={this.state.modal}
              info={this.state.modalInfo}
              link={this.state.modalLink}
              leaveGame={this.leaveGame}
              language={this.props.language}
              loading={this.state.loading}
              cancelModal={this.cancelModal}
            />
            :
            null
          }
          {this.state.successes ?
            <SuccessMessage
              successes={this.state.successes}
              language={this.props.language}
              resolveSuccess={this.resolveSuccess}
            />
            :
            null}
          {this.state.attackMenu.display ? <AttackMenu
            key={'attack-menu'}
            teams={game.teams}
            order={game.playOrder}
            data={this.state.attackMenu}
            skipAttack={this.skipAttack}
            shuffleAttack={this.shuffleAttack}
            resetAttackMenu={this.resetAttackMenu}
            kickTeam={this.kickTeam}
            language={this.props.language}
            localID={this.state.localID}
          />
            : null
          }
          {game.results ? <Results
            results={game.results}
            reset={this.reset}
            mode={this.props.mode}
          />
            : null}
          <div className="board-inner">
            <BoardTopbar
              teams={game.teams}
              order={game.playOrder}
              currentTeam={game.currentTeam}
              activeID={game.activeID}
              shuffleAttack={this.shuffleAttack}
              mode={this.props.mode}
              language={this.props.language}
              displayAttackMenu={this.displayAttackMenu}
              isFirstFlip={game.isFirstFlip}
              localID={this.state.localID}
            />
            <div>
              <label>card size (カード大きさ)</label>
              <input name="card-width-range" type="range" className="card-width-range" min={30} max={500} step={5} value={this.state.cardWidth} onChange={this.handleCardWidthChange}></input>
            </div>
            <div className="cards-con-out">
              <div className="cards-container" style={{gridTemplateColumns: `repeat( auto-fill, minmax(${this.state.cardWidth}px, 1fr))`}}>
                {game.decks[game.teams[game.activeID].usingCards].cardOrder.map((key, index) => {
                  const card = game.decks.og.cards[key];
                  return (
                    <div key={card.key} className="card-container">
                        <Card
                          id={`card-${index}`}
                          type={card.type}
                          content={card.content}
                          selectorKey={key}
                          match={card.id}
                          alt={card.alt}
                          isFlipped={card.isFlipped}
                          onClick={() => this.flipController(card.key)}
                          postFlip={() => this.postFlipController(card.key)}
                          isMatched={card.isMatched}
                          dStatus={card.displayStatus}
                          status={card.status}
                          setDisplayStatus={this.setCardDisplayStatus}
                          inactive={this.props.mode !== 'local' && game.activeID !== this.state.localID ? true : false}
                          autoplay={true}
                        />
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
          <div className="board-button-box">
            {this.state.loading.game ?
              <LoadingIndicator />
              :
              <Button key={'return-button'} variant="danger" className="board-button"
                onClick={() => this.displayModal('back-button')}
              >
                <ion-icon name="chevron-back-outline"></ion-icon>
              </Button>
            }
          </div>
          {this.props.mode === 'host' ? <div key={'game-code'} className="board-game-id">{lang[0]}</div> : null}
        </div>
    )
  }
}

export default Board;

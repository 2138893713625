import React, {useRef, useState} from "react";

// card dStatuses: 

// 1 - hidden
// 2 - flipping
// 3 - flipped
// 4 - matching
// 5 - matched

const Card = props => {
  const ref = useRef();
  let html;
  let c = 'gamecard';

  const [isFlipping, setIsFlipping] = useState(false);
  const [isMatching, setIsMatching] = useState(false);

  const handleFlip = () => {
    if (isFlipping) return;
    setIsFlipping(true);
    return ref.current.addEventListener('animationend', handlePostFlip);
  }

  const handlePostFlip = () => {
    ref.current.removeEventListener('animationend', handlePostFlip);
    ref.current.classList.remove('flip');
    if (!props.inactive) props.setDisplayStatus(props.selectorKey, 3);
  }

  const handlePostMatching = () => {
    ref.current.removeEventListener('animationend', handlePostMatching);
    ref.current.classList.remove('flip');
    ref.current.classList.add('matched');
    if (!props.inactive) props.setDisplayStatus(props.selectorKey, 5);
  }

  const handleMatching = () => {
    if (isMatching) return;
    setIsMatching(true);
    return setTimeout(() => {
      if (!ref.current) return;
      ref.current.classList.add('flip');
      return ref.current.addEventListener('animationend', handlePostMatching);
    }, 1000);
  }

  if (isFlipping && props.dStatus === 3 && !props.inactive) {
    setIsFlipping(false);
  }

  if (isMatching && props.dStatus === 5 && !props.inactive) {
    setIsMatching(false);
  }

  switch (props.type) {
    case 2:
        html = <img data-key={props.selectorKey} alt={props.alt} src={props.content}></img>;
        break;
    case 3:
        html = <iframe data-key={props.selectorKey} src={props.content} title="YouTube video player" frameBorder="0" autoPlay allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        break;
    case 4:
        html = <audio data-key={props.selectorKey} src={props.content} controls autoPlay={props.status === 3 && props.dStatus === 5 ? false : props.autoplay}></audio>
        break;
    default:
        html = <p data-key={props.selectorKey}>{props.content}</p>
  }

  switch (props.dStatus) {
    case 2:
      c += ' flipped scale flip';
      handleFlip();
      break;
    case 3:
      c += ' flipped scale';
      break;
    case 4:
      c += ' flipped scale';
      handleMatching();
      break;
    case 5:
      c += ' flipped scale matched';
      break;
    default:
      break;
  }

  return (
    <div ref={ref} onClick={props.dStatus === 1 ? props.onClick : null} id={`${props.id}`} className={c}>
      <div className="card-content" >
        {props.dStatus !== 1 ?
        html
        : ''}
      </div>
    </div>
  );
}

export default Card;

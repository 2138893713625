import React, { Component } from 'react';
import MenuCustomCollectionItem from './MenuCustomCollectionItem/MenuCustomCollectionItem';
import styled from 'styled-components';
import './MenuCustomCollectionItems.css';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import Fade from 'react-bootstrap/Fade';
import LoadingIndicator from '../../hoc/LoadingIndicator';
import { Link } from 'react-router-dom';



const StyleWrapper = styled.div`
            @media (max-width: 900px) and (min-width: 550px) {
                .CollectionCardsGrid {
                    display: grid;
                    grid-template-columns: repeat(auto-fill, minmax(33%, 1fr));
                }

            }

            @media (max-width: 549px) {
                .CollectionCardsGrid {
                    grid-template-columns: repeat(auto-fill, minmax(33%, 1fr));
                }
            }

            
        `


class MenuCustomCollectionItems extends Component {

    constructor(props) {
        super(props);
        this.refHost = React.createRef();
        this.refJoin = React.createRef();
    }

    componentDidMount() {
    }

    componentDidUpdate() {

    }

    async cacheImages(arr) {
        // console.log('cacheimages called')
        await arr.map(src => {
          return new Promise(async function(resolve, reject) {
            const img = new Image();
    
            img.src = src;
            // console.log('saved image');
          })
        });
    }

    render() {
        
        return this.props.collections && !this.props.loading ?
            this.props.collections.map( (collection, index) => {
                return <StyleWrapper key={index}>
                    <Fade appear={true} in={true} timeout={500}>
                    <div key={index} className="CollectionItemBox">
                        <button className="delete-cloud-save" onClick={() => this.props.modal('delete-collection', {collection: this.props.currentUserID, id: collection.id})}>
                            <ion-icon name="close-outline"></ion-icon>
                        </button>
                        <p className="CollectionItemTitle">{collection.title}</p>
                        <small>Created on {new Date(collection.createdOn).toDateString()}</small>
                        <small>Edited on {new Date(collection.lastEdit).toDateString()}</small>
                        <div className="collection-item-buttons-row">
                                <button title="PLAY!" className="red" onClick={() => this.props.openMenu(collection.id, `/play#${collection.id}`)}>
                                    PLAY!
                                </button>
                                <button title="Share this deck with the world!" onClick={collection.shareID ? () => this.props.modal('delete-collection', {collection: 'share', id: collection.shareID}) : () => this.props.modal('share', collection)}>
                                    { collection.shareID ? 'Unshare' : 'Share'}
                                </button>
                                <Link to={{
                                    pathname: '/editor',
                                    hash: `#${collection.id}`
                                }}>
                                    <button className="cloud-save-btn" onClick={() => this.props.edit(collection.id)}>
                                        <ion-icon name="construct-outline"></ion-icon>
                                    </button>
                                </Link>
                        </div>
                        {collection.shareID ? <p>Shared at flipget.app/share#{collection.shareID}</p> : null}
                        <OverlayTrigger
                            trigger="click"
                            placement="auto"
                            rootClose={true}
                            delay={{ show: 250, hide: 400 }}
                            overlay={
                                //TODO: fix exit fade
                                <Popover id="popover-basic">
                                    <MenuCustomCollectionItem 
                                    title={collection.title}
                                    cards={collection.cards}
                                    />
                                </Popover>

                            }
                        >
                            <div className="show-items-section">
                                <ion-icon name="caret-down-outline"></ion-icon>
                            </div>
                        </OverlayTrigger>
                    </div>

                </Fade>
                </StyleWrapper>
        
        })
        : 
        <LoadingIndicator/>
    }
}

export default MenuCustomCollectionItems;
import React from 'react';
import SortingButton from './SortingButton/SortingButton';

import './SortingButtons.css';

const SortingButtons = props => {

    const buttons = [
        {
            name: 'Created',
            type: 'created'
        },
        {
            name: 'Edited',
            type: 'edited'
        },
        {
            name: 'Name',
            type: 'alpha'
        },
    ];
    
    return <div className="sorting-buttons-box">
        {buttons.map(button => {
            return (
                
                <SortingButton
                    key={`sort-${button.name}`}
                    active={props.type === button.type ? true : false}
                    descending={props.descending}
                    name={button.name}
                    type={button.type}
                    clicked={props.clicked}
                />
                
            )
        })}
    </div>
}

export default SortingButtons;
import React, { useState } from 'react';
import './ErrorMessage.css';
import { Button, Modal } from 'react-bootstrap';


const ErrorMessage = props => {

    let lang = props.language;

    let [clicked, handleClick] = useState(false);

    let currentErrorMessage = props.error;

    const errorMessages = {
        'en': {
            1: 'Please input a title and at least one card with text and image',
            2: 'Please login and try again',
            3: 'Check your internet connection and try again',
            4: 'Email or password is incorrect',
            5: `Oops! We aren't sure what happened. Error: ${props.error}`,
            6: 'It seems your email is invalid',
            7: 'Add a team',
            8: 'Add a game code and a team',
            9: 'No game with that code found.',
            10: <div className="results-title">Game over!
                {props.results ? [<div key={'results-winner'} className="results-winner">{ props.results.length > 1 && props.results[0].points === props.results[1].points ? `It's a tie!` : `Winner: ${props.results[0].name}`}</div>,
                    props.results.map((team, index) => <div key={index} className="results-other">
                    <div className="results-justify-left">{team.name}</div>
                    <div className="results-justify-right">{team.points}</div>
                </div>)] : null}
            </div>,
            11: 'Left the game.',
            12: 'No collections found.',
            13: 'Sign in or create an account to get started.',
            14: 'Confirm that the two passwords match',
            15: 'Your password is too short. Make sure it has at least 6 characters.'
        },
        'jp': {
            1: 'Please input a title and at least one card with text and image',
            2: 'Please login and try again',
            3: 'Check your internet connection and try again',
            4: 'Email or password is incorrect',
            5: `Oops! We aren't sure what happened. Error: ${props.error}`,
            6: 'It seems your email is invalid',
            7: 'Add a team',
            8: 'Add a game code and a team',
            9: 'No game with that code found.',
            10: <div className="results-title">Game over!
                {props.results ? [<div key={'results-winner'} className="results-winner">{ props.results.length > 1 && props.results[0].points === props.results[1].points ? `It's a tie!` : `Winner: ${props.results[0].name}`}</div>,
                    props.results.map((team, index) => <div key={index} className="results-other">
                    <div className="results-justify-left">{team.name}</div>
                    <div className="results-justify-right">{team.points}</div>
                </div>)] : null}
            </div>,
            11: 'Left the game.',
            12: 'No collections found.',
            13: 'ログインまたは新しいアカウントを作ってください。',
            14: '新しいパスワードまた確認してください。',
            15: `入力したパスワードは6文字以上か確認してください。`
        }
    }

    let curLangErrorMessages = errorMessages[lang];

    switch (props.error) {
        case 'auth/network-request-failed': 
            currentErrorMessage = curLangErrorMessages[3];
            break;
        case 'auth/wrong-password': 
            currentErrorMessage = curLangErrorMessages[4];
            break;
        case 'auth/invalid-email': 
            currentErrorMessage = curLangErrorMessages[6];
            break;
        case 'input/incorrect-arguments': 
            currentErrorMessage = curLangErrorMessages[1];
            break;
        case 'auth/no-user': 
            currentErrorMessage = curLangErrorMessages[2];
            break;
        case 'permission-denied':
            currentErrorMessage = curLangErrorMessages[2];
            break;
        case 'game-noTeams':
            currentErrorMessage = curLangErrorMessages[7];
            break;
        case 'game-noIDNoTeam':
            currentErrorMessage = curLangErrorMessages[8];
            break;
        case 'game-noGameFound':
            currentErrorMessage = curLangErrorMessages[9];
            break;
        case 'game-over':
            currentErrorMessage = curLangErrorMessages[10];
            break;
        case 'game-leave':
            currentErrorMessage = curLangErrorMessages[11];
            break;
        case 'user-no-collections':
            currentErrorMessage = curLangErrorMessages[12];
            break;
        case 'cloud-save-no-user':
            currentErrorMessage = curLangErrorMessages[13];
            break;
        case 'pass-mismatch':
            currentErrorMessage = curLangErrorMessages[14];
            break;
        case 'pass-too-short':
            currentErrorMessage = curLangErrorMessages[15];
            break;
        default: currentErrorMessage = errorMessages[5];
    }

    // console.log(currentErrorMessage);
    
    return (
        <Modal
            size="lg"
            centered
            aria-labelledby="contained-modal-title-vcenter"
            show={props.error && !clicked}
        >
            <Modal.Header>
                <Modal.Title>
                    Oops!
                </Modal.Title>
            </Modal.Header>
            <Modal.Body
            >
                {currentErrorMessage}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => {
                        handleClick(clicked = true);
                        setTimeout(() => props.clicked(), 200);
                    }
                    } variant="secondary">
                        <ion-icon name="close-outline"></ion-icon>
                </Button>
            </Modal.Footer>
                
    </Modal>
    )
}

export default ErrorMessage;
import React from 'react';
import InfoPage from '../InfoPage/InfoPage';

const HowTo = () => {
    return (
        <InfoPage
            title={"How To Play"}        
        >
            <p>
            flipGET is a dynamic take on the classic card matching game known by many as concentration. In flipGET, players take turns flipping cards in the hopes of finding a match. When you flip correctly, you get. However, in addition to memory skills, players have access to flipAttacks, abilities that can be used to trip up their opponents. <br></br>To make your own card sets, first create an account. You'll then be able to save your own collections, play local games, and host online games. If you don't have an account, you can still join games by clicking the join button and typing in your friend's game ID. Happy flippin'!
            </p>
        </InfoPage>
    )
}

export default HowTo;
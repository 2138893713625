import React from "react";

import "./Logo.css";

const logo = (props) => {
  return (
    <div className="logo">
      <div className="logo-f">
        <div className="logo-inner-1-1">
          <div className="logo-front">
            <div className="text-flex">
              <p>f</p>
            </div>
          </div>
          <div className="logo-back">
            <div className="text-flex">
              <p>f</p>
            </div>
          </div>
        </div>
      </div>
      <div className="logo-l">
        <div className="logo-inner-1-2">
        <div className="logo-front">
            <div className="text-flex">
              <p>l</p>
            </div>
          </div>
          <div className="logo-back">
            <div className="text-flex">
              <p>l</p>
            </div>
          </div>
        </div>
      </div>
      <div className="logo-i">
        <div className="logo-inner-1-3">
        <div className="logo-front">
            <div className="text-flex">
              <p>i</p>
            </div>
          </div>
          <div className="logo-back">
            <div className="text-flex">
              <p>i</p>
            </div>
          </div>
        </div>
      </div>
      <div className="logo-p">
        <div className="logo-inner-1-4">
        <div className="logo-front">
            <div className="text-flex">
              <p>p</p>
            </div>
          </div>
          <div className="logo-back">
            <div className="text-flex">
              <p>p</p>
            </div>
          </div>
        </div>
      </div>
      <div className="logo-G">
        <div className="logo-inner-2-1">
        <div className="logo-front">
            <div className="text-flex">
              <p>G</p>
            </div>
          </div>
          <div className="logo-back">
            <div className="text-flex">
              <p>G</p>
            </div>
          </div>
        </div>
      </div>
      <div className="logo-E">
        <div className="logo-inner-2-2">
        <div className="logo-front">
            <div className="text-flex">
              <p>E</p>
            </div>
          </div>
          <div className="logo-back">
            <div className="text-flex">
              <p>E</p>
            </div>
          </div>
        </div>
      </div>
      <div className="logo-T">
        <div className="logo-inner-2-3">
        <div className="logo-front">
            <div className="text-flex">
              <p>T</p>
            </div>
          </div>
          <div className="logo-back">
            <div className="text-flex">
              <p>T</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default logo;

import React, {useEffect, useRef} from 'react';
import Logo from './Logo/Logo';
import UserInfo from './UserInfo/UserInfo';
import MainMenuButtons from './MainMenuButtons/MainMenuButtons';
import { withRouter, Link } from 'react-router-dom';
import styles from './Header.module.css';
import { headerAppVersion } from '../../functions/headerAppVersion';

const Header = props => {
    const ref = useRef(null);

    const handleScroll = () => {
        if (location.pathname.match(/(play|share)/)) return;
        if (window.scrollY > ref.current.offsetTop) {
            ref.current.classList.add('sticky');
            return document.body.style.paddingTop = ref.current.offsetHeight + 'px';
        } else {
            ref.current.classList.remove('sticky');
            return document.body.style.paddingTop = 0;
        } 
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
            document.body.style.paddingTop = 0;
        };
    });

    const { location } = props;
    if (location.pathname.match(/(play|share)/)){
        return null;
    }
    
    return (
        <header ref={ref}>
            <div className={styles.navLeft}>
                <div className={styles.siteInfo}>
                    <nav><Logo/></nav>
                    <Link to="/updates">
                        <p className={styles.version}>last updated on {new Date(headerAppVersion()).toDateString()}</p>
                    </Link>
                </div>
                <div className={styles.siteInfoInner}>
                    <nav>
                        <Link to="/about">
                            About
                        </Link>
                    </nav>
                    <nav>
                        <Link to="/how-to">
                            How To Play
                        </Link>
                    </nav>
                    <nav>
                        <Link to="/privacy">
                            Privacy Policy
                        </Link>
                    </nav>
                    <nav>
                        <Link to="/contact">
                            Contact
                        </Link>
                    </nav>
                </div>
            </div>
            <div className={styles.navRight}>
                <nav>
                    <MainMenuButtons
                        mode={props.mode}
                        displayModal={props.displayModal}
                        language={props.language}
                    />
                </nav>
                <nav>
                    <UserInfo
                        key={'user-info'} 
                        user={props.user}
                        signUserOut={props.signUserOut}
                        inputChangedHandler={props.inputChangedHandler}
                        setCurrentUser={props.setCurrentUser}
                        createNewUser={props.createNewUser}
                        language={props.language}
                        creatingNewAccount={props.creatingNewAccount}
                        toggleDisplayCreateUserMenu={props.toggleDisplayCreateUserMenu}
                        displayModal={props.displayModal}
                        loading={props.loading}
                        mode={props.setMode}
                    />
                </nav>
            </div>
        </header>
    )
}

export default withRouter(Header);
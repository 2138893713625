import React from 'react';
import { Button } from 'react-bootstrap';

import './SortingButton.css';

const SortingButton = props => {
    return (
        <Button className="sorting-button" size="sm" variant="secondary" active={props.active} onClick={() => props.clicked(props.type)}>
            {props.name}
            {props.active ? props.descending ? <ion-icon name="chevron-down-outline"></ion-icon> : <ion-icon name="chevron-up-outline"></ion-icon> : null }
        </Button>
    )
}

export default SortingButton;
const wordsToAvoid = ['sex', 'ass', 'tit', 'cum'];

const letters = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];

const simpleShareID = () => {
    const gameIDArr = [];

    do {
        const ind = Math.floor(Math.random() * letters.length);
        gameIDArr.push(letters[ind])
    } while (gameIDArr.length < 3 || wordsToAvoid.includes(gameIDArr.join('')))

    return gameIDArr.join('');
}

export default simpleShareID;
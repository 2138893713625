import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
// import language from '../../../lib/language';

import './AttackMenu.css'

//TODO: center menu and edit buttons

const AttackMenu = props => {
    const [show, setShow] = useState(true);

    // let lang = language[props.language];

    const order = [...props.order.filter(team => team !== props.localID)]

    function getMenuName(name) {
        return name.split('').reduce((acc, val, index) => {
            if (index !== 0) acc+= val
            return acc;
        }, '')
    }

    return <Modal
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={show}
            onHide={() => setShow(false)}
            onExited={() => props.resetAttackMenu()}
            >
            <Modal.Header>
                {props.data.type.split('')[0].toUpperCase() + getMenuName(props.data.type) + ' menu'}
            </Modal.Header>
            <Modal.Body>
            {order.map((team, index) => {
                return <button key={index} className="attack-menu-btn" onClick={() => {
                    if (props.data.type === 'skip') {
                        props.skipAttack(team)
                    } else if (props.data.type === 'shuffle') {
                        props.shuffleAttack(team)
                    } else {
                        props.kickTeam(team)
                    }
                    setShow(false);
                }}>
                    {props.teams[team].name}
                </button>
            })}
            </Modal.Body>
            <Modal.Footer>
            <button className="attack-menu-close-btn" onClick={() => setShow(false)}>
                <ion-icon name="close-outline"></ion-icon>
            </button>
            </Modal.Footer>
            </Modal>
}

export default AttackMenu;
import React from 'react';
import Team from './Team/Team';

const Teams = props => {

    // const [mouseDown, setMouseDown] = useState(false);
    // const [dragging, setDrag] = useState(false);
    // const [dragItem, setDragItem] = useState('');
    let leng = props.teams.length;
    let dragItem, hoverItem, position;
    let touchDrag = false;

    function handleDragStart(type, e) {
        if (!e.target.classList.contains('team-box')) return;
        // console.log('drag started!');
        // capture drag item info for later
        if (type === 'mouse') e.dataTransfer.setData("ind", e.target.dataset.ind);
        if (type === 'touch') touchDrag = true;
        dragItem = e.target;
    }

    function drag(type, e) {
        if (type === 'mouse') e.preventDefault();
        if (type === 'touch' && !touchDrag) return;

        // add class
        e.target.classList.add('drag-alert');
    }

    function handleDrop(type, e) {
        // console.log(e.target.classList, type, touchDrag);
        if ((type === 'touch' && (!touchDrag || !hoverItem)) || !e.target.classList.contains('team-box')) return;
        if (type === 'mouse') e.preventDefault();
        const dragInd = type === 'mouse' ? parseFloat(e.dataTransfer.getData("ind")) : parseFloat(dragItem.dataset.ind);
        let dropInd = type === 'mouse' ? parseFloat(e.target.dataset.ind) : parseFloat(hoverItem.dataset.ind);

        // console.log({dragInd, dropInd});
        //update state to match drop
        if (dragInd === dropInd) {
            // console.log('hey i dropped on myself');
            const items = [...document.querySelectorAll(".team-box")];
            // console.log({items: items});
            dropInd = items.findIndex(item => parseFloat(item.dataset.ind) === dragInd);
            // console.log({dragInd, dropInd});
        }

        props.updateTeams(dragInd, dropInd);
        if (dragItem) dragItem.classList.remove('drag-alert');
        if (dragItem.classList.contains('hover-alert')) dragItem.classList.remove('hover-alert');
        touchDrag = false;
    }

    function handleDragOver(e) {
        if (!e.target.classList.contains('team-box')) return;
        e.preventDefault();
        const mouseY = e.clientY;
        insertElement(e.target, mouseY);
    }

    function insertElement(item, y) {
        if (item) {
            const coordinates = item.getBoundingClientRect();
            position = (y - coordinates.top) / item.offsetHeight;
            if (position > .5) {
                item.insertAdjacentElement('afterend', dragItem);
            } else {
                item.insertAdjacentElement('beforebegin', dragItem);
            }
            return item.classList.add('hover-alert');
        }
    }

    function handleDragLeave(e) {
        e.preventDefault();
        return e.target.classList.remove('hover-alert');
    }

    function handleTouchMove(e) {
        if (!touchDrag) return;

        // add class
        e.target.classList.add('drag-alert');

        // console.log(e);
        const touchY = e.touches[0].clientY;
        // console.log({touchY})
        const items = [...document.querySelectorAll('.team-box')];
        const nextHoverItem = items.find(item => {
            const coordinates = item.getBoundingClientRect();
            return coordinates.top <= touchY && coordinates.bottom >= touchY;
        });
        
        const notHovered = items.filter(item => item !== nextHoverItem);
        notHovered.map(item => item.classList.remove('hover-alert'));

        if (!nextHoverItem) {
            return handleTouchCancel();
        }

        hoverItem = nextHoverItem;

        insertElement(hoverItem, touchY);
    }

    function handleTouchCancel() {
        const dragInd = parseFloat(dragItem.dataset.ind);
        let dropInd = parseFloat(hoverItem.dataset.ind);

        if (dragInd === dropInd) {
            // console.log('hey i dropped on myself');
            const items = [...document.querySelectorAll(".team-box")];
            // console.log({items: items});
            dropInd = items.findIndex(item => parseFloat(item.dataset.ind) === dragInd);
            // console.log({dragInd, dropInd});
        }

        if (dragItem) dragItem.classList.remove('drag-alert');
        if (dragItem.classList.contains('hover-alert')) dragItem.classList.remove('hover-alert');
        touchDrag = false;
        return props.updateTeams(dragInd, dropInd);
    }
   
    return (
        <div 
            className="teams-drag-drop"
        >
            {props.teams.map( (team, index) => {
                return <Team
                        editTeamName={(event) => props.editTeamName(event, team.id)}
                        name={team.name}
                        deleteTeam={props.mode !== 'host'? () => props.deleteTeam(index): null}
                        id={team.id}
                        key={team.id}
                        language={props.language}
                        index={index}
                        isSolo={leng > 1 ? false : true}
                        mode={props.mode}
                        handleDragStart={handleDragStart}
                        drag={drag}
                        handleDragOver={handleDragOver}
                        handleDrop={handleDrop}
                        handleDragLeave={handleDragLeave}
                        handleTouchMove={handleTouchMove}
                    />
            })}
        </div>
    )
    
    
};

export default Teams;

import React from 'react';
import Card from "../../Card/Card";
import './MenuCustomCollectionItem.css';

const MenuCustomCollectionItem = props => {

    // console.log(props.cards);
    
    return <div className="collection-preview-flex">
            {props.cards.map(card => { 
                    return (
                        <div key={card.id} className="preview-box">
                        {Object.keys(card.content).map(num => {
                            return <Card
                            key={num}
                            isFlipped={true}
                            onClick={null}
                            id={num}
                            content={card.content[num].content}
                            type={card.content[num].contentType}
                            dStatus={3}
                        />
                        })}
                    </div>
                    )
                })}
    </div>
}

export default MenuCustomCollectionItem;
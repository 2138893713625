export const updates = () => {
    return [
        {
            date: "July 28, 2024",
            items: [
                "Routine",
                "Change card size",
                "Game view styling"
            ]
        },
        {
            date: "December 15, 2022",
            items: [
                "Routine updates.",
                "New contact email.",
            ]
        },
        {
            date: "February 4, 2022",
            items: [
                "An autoskip feature for those who are running into issues with games freezing after in-game media plays or who are on slower connections."
            ]
        },
        {
            date: "December 13, 2021",
            items: [
                "The updates page.",
                "The game will wait for audio clips to finish playing or to be paused before allowing the next flip or moving onto the next team after a mismatch.",
                "Contact information!",
                "Some advice on using videos and audio on the editor page.",
                "Small styling changes.",
                "Bug fixes, including one that kept folks from using shared card collections.",
                "Removed the language pane footer."
            ]
        },
        {
            date: "September 18, 2021",
            items: [
                "Card types! You're now able to choose from four different card types: text, image, video, and audio. All of your previously made card collections will be automatically updated to match this new format.",
                "Online bug fixes and other improvements."
            ]
        },
    ]
}
import React, {useState} from "react";
import MenuCustomCollectionItems from "../MenuCustomCollectionItems/MenuCustomCollectionItems";
import styled from "styled-components";
import "./CloudSavesMenu.css";
import language from "../../lib/language";
import { Alert } from "react-bootstrap";
import LoadingIndicator from "../../hoc/LoadingIndicator";
import SortingButtons from './SortingButtons/SortingButtons';
import PaginationFooter from './Pagination/Pagination';
import { Link, withRouter } from 'react-router-dom';

const StyledWrapper = styled.div`
  @media (max-width: 900px) and (min-width: 550px) {
    .CloudCollectionsListBox {
      display: grid;
      width: 100%;
      height: auto;
      grid-template-columns: 50% 50%;
      column-gap: 1%;
      justify-items: stretch;
      row-gap: 2vh;
    }

    .cloud-saves-title {
      font-size: 2.5vw;
    }
  }

  @media (max-width: 549px) {
    .cloud-collections-list-box-outer {
      width: 90%;
    }

    .CloudCollectionsListBox {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: auto;
      align-items: stretch;
      grid-template-columns: unset;
      column-gap: unset;
      row-gap: unset;
    }

    .CloudCollectionsListBox div {
      margin: 3px auto;
      width: 100%;
    }

    .cloud-saves-title {
      font-size: 3vw;
    }
  }
`;

const sortings = {
  created: {
      display: 'Recently created',
      parameter: 'createdOn',
  },
  edited: {
      display: 'Recently edited',
      parameter: 'lastEdit',
  },
  alpha: {
      display: 'Alphabetical',
      parameter: 'name',
  }
};

const itemsPerPage = 7;

const pagination = items => {

  const pages = [];

  items.map((item, index) => {
      const page = Math.floor(index / itemsPerPage);

      if (!pages[page]) {
          pages.push([])
      }
      
      const pageArr = pages[page];

      pageArr.push(item);

      return pages[page] = pageArr;
  })
  
  return pages;
}

const CloudSavesMenu = (props) => {

  const initialState = {
    type: 'created',
    descending: true,
    page: 1
  };

  const [state, setState] = useState(initialState);
  let localCloudSaves = null;
  const { location } = props;
  if (location.pathname.match(/(play|share)/)){
    return null;
  }

  if (props.user) {
    localCloudSaves = [...props.user.cloudSaves];

    if (state.type === 'alpha') {
        localCloudSaves.sort((a,b) => {
            let fa = a.title.toLowerCase(),
            fb = b.title.toLowerCase();

            if (fa < fb) {
                return -1;
            }
            if (fa > fb) {
                return 1;
            }
            return 0;
        });
    } else {
        
        const parameter = sortings[state.type].parameter;
        
        localCloudSaves.sort((a,b) => {
            return b[parameter] - a[parameter];
        });

        // console.log(localCloudSaves);
    }


    if (!state.descending) {
      localCloudSaves.reverse();
    }
  }
  
  
    
  const clicked = (type) => {

    // console.log(type, 'clicked');
      
      let order = true;

      if (state.type === type) {
          order = !state.descending
      }

      const newState = {...state};

      newState.type = type;
      newState.descending = order;
      
      setState(newState)
  }

  const pageClicked = page => {
    if (page === state.page) {
      return;
    } else {
      const newState = {...state};

      newState.page = page;

      setState(newState);
    }
  }

  // console.log(state);

  let lang = language[props.language];

  return (
    <StyledWrapper>
      <div className="CloudSavesMenu">
        {props.user && !props.loading ? (
          <div className="cloud-collections-list-box-outer">
            <p className="cloud-saves-title">{lang[41]}</p>
            {props.user.cloudSaves ? 
            <SortingButtons
              clicked={clicked}
              type={state.type}
              descending={state.descending}
            />
            : null
            }
            <div
              key={"cloud-collections-list-box"}
              className="CloudCollectionsListBox"
            >
              <Link to="/editor#new">
                <button
                  onClick={
                    props.location.pathname.match(/editor/)
                      ? () => props.cleanup()
                      : () => props.setPage("Custom Collection")
                  }
                  className="add-collection-btn"
                >
                  <ion-icon name="add-outline"></ion-icon>
                  <p>new card collection</p>
                </button>
              </Link>
              {localCloudSaves ? 
                <MenuCustomCollectionItems
                  collections={pagination(localCloudSaves)[state.page - 1]}
                  page={state.page}
                  sort={state.type}
                  descending={state.descending}
                  currentUserID={props.user.id}
                  deleteDocumentFromFirebase={props.deleteDocumentFromFirebase}
                  language={props.language}
                  edit={props.edit}
                  modal={props.modal}
                  loading={props.loading}
                  openMenu={props.openGameMenu}
                  setMode={props.setMode}
                  mode={props.mode}
                />
              :
              <Alert variant="light" style={{ width: "50%", margin: "auto" }}>
                {lang[31]}
              </Alert>
              }
            </div>
            <PaginationFooter
              active={state.page}
              clicked={pageClicked}
              pages={pagination(localCloudSaves).length}
            />
          </div>
        ) : 
        <Alert variant="light" style={{ width: "50%", margin: "auto" }}>
          {lang[31]}
        </Alert>
        }
        {props.loading ? <LoadingIndicator /> : null}
      </div>
    </StyledWrapper>
  );
};

export default withRouter(CloudSavesMenu);

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styles from "./InfoPage.module.css"; 

const InfoPage = (props) => {
    
    const [isFirstMount, setIsFirstMount] = useState(true);

    useEffect(() => {
        if (isFirstMount) {
            if (window.scrollY > 0) window.scrollTo(0, 0);
        }

        if (isFirstMount) return setIsFirstMount(false);
    }, [isFirstMount]);
    
    return (
    <div className={styles.outer}>
        <Link to="/">
            <button>
                X
            </button>
        </Link>
        <h2> 
            {props.title}
        </h2>
        {props.children}
    </div>
    )
}

export default InfoPage;
import React from 'react';
import TopbarItem from './TopbarItem/TopbarItem';

import './BoardTopbar.css';

const BoardTopbar = props => {
    
    return (
      <div key={'board-topbar'} className="board-topbar">
        <div className="board-topbar-inner">
        {props.order.map((team, index) => {
          return (
            <TopbarItem
              team={props.teams[team]}
              index={index}
              language={props.language}
              activeID={props.activeID}
              mode={props.mode}
              localID={props.localID}
              displayAttackMenu={props.displayAttackMenu}
              key={`topbar-${index}`}
            />
          )}
        )}
        </div>
      </div>
          
  )
};

export default BoardTopbar;
import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

const Results = props => {

    const [show, setShow] = useState(true);

    function handleReset() {
        setShow(false);
        return props.reset();
    }

    return (

        <Modal
            size="lg"
            centered
            aria-labelledby="contained-modal-title-vcenter"
            show={show}
        >
            <Modal.Header>
                <Modal.Title>
                    Game over!
                </Modal.Title>
            </Modal.Header>
            <Modal.Body
            >
            <div key={'results-winner'} className="results-winner">{ props.results.length > 1 && props.results[0].points === props.results[1].points ? `It's a tie!` : `Winner: ${props.results[0].name}`}</div>
            {props.results.map((team, index) => (
                <div key={index} className="results-other">
                    <div className="results-justify-left">{team.name}</div>
                    <div className="results-justify-right">{team.points}</div>
                </div>))}
            </Modal.Body>
            <Modal.Footer>
                {props.mode !== "join" ? <Button onClick={() => handleReset()}>
                    Replay!
                </Button>
                :
                null}
                <Button onClick={() => {
                        setShow(false)
                    }
                    } variant="secondary">
                        <ion-icon name="close-outline"></ion-icon>
                </Button>
            </Modal.Footer>
                
    </Modal>
       
    )
}

export default Results;
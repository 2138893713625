export const buildCard = (content, type, id, key) => {
    const card = {
        content: content,
        type: type,
        matchID: id,
        alt: content,
        isFlipped: false,
        isMatched: false,
        status: 1,
        displayStatus: 1,
        key: key,
        index: null
    }
    
    return card;
}

export const buildTeam = (name, id, flipAttacks) => {
    const team = {
        name: name,
        id: id,
        points: 0,
        matchedCards: [],
        usingCards: 'og',
        flipAttacks: {
            shuffle: false,
            skip: flipAttacks.skip ? true : false
        },
        shouldSkip: 0
    };

    return team;
}
import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import language from '../../lib/language';
import LoadingIndicator from '../LoadingIndicator';
import CreateGameMenu from '../../components/CreateGameMenu/CreateGameMenu';
import { Link } from 'react-router-dom';
import MenuCustomCollectionItem from "../../components/MenuCustomCollectionItems/MenuCustomCollectionItem/MenuCustomCollectionItem";

import './ModalMessage.css';

const ModalMessage = props => {

    const [show, setShow] = useState(true);
    const [disabled, setDisabled] = useState(true);
    let modalInfo, modalHeading, modalFunc, modalFuncLabel;
    let lang = language[props.language];

    function handleEnterKey(e) {
        if (e.keyCode !== 13) return;
        const modal = document.querySelector('.modal-body');
        const inputs = [...modal.querySelectorAll('input[type="text"]')];
        // console.log(inputs);
        if (!inputs.some(input => !input.value.length)) {
            document.querySelector('.modal-button').click();
        }
    };

    function handleNewPassInput() {
        if (document.getElementById('new-user-pass').value.length < 6) {
            return setDisabled(true);
        }

        return setDisabled(false);
    }

    useEffect(() => {
        document.addEventListener('keypress', handleEnterKey);

        return function() {
            document.removeEventListener('keypress', handleEnterKey);
        }
    })

    useEffect(() => {
        const pass = document.getElementById('new-user-pass');
        if (pass) {
            pass.addEventListener('change', handleNewPassInput);
            pass.addEventListener('keyup', handleNewPassInput);
        } else {
            return setDisabled(false);
        }

        return function() {
            pass.removeEventListener('change', handleNewPassInput);
            pass.removeEventListener('keyup', handleNewPassInput);
        }
    }, [])

    function handleClick() {
        if (modalFunc) modalFunc();
        setShow(false);
    }

    switch (props.modal) {
        case 'delete-collection':
            modalHeading =　lang[36];    
            modalInfo = lang[33];
            modalFunc = () => props.deleteCollection(props.info.collection, props.info.id);
            modalFuncLabel = lang[34];
            break;
        case 'back-button':
            modalHeading = lang[36];    
            modalInfo = lang[37];
            modalFunc = () => props.leaveGame();
            modalFuncLabel = lang[38];
            break;
        case 'pass-change':
            modalHeading = lang[42];
            modalInfo = <div>
                <p>{lang[43]}</p>
                <input type="password" id="change-pass-input" placeholder={lang[45]}></input>
                <input type="password" id="change-pass-input-confirm" placeholder={lang[46]}></input>
            </div>;
            modalFunc = () => props.changePass();
            modalFuncLabel = lang[44];
            break;
        case 'user-reauth':
            modalHeading = lang[47];
            modalInfo = <div>
                <p>{lang[48]}</p>
                <input type='password' id ='reauth-pass-input' placeholder={lang[3]}></input>
            </div>
            modalFunc = () => props.reauthenticateUser();
            modalFuncLabel = lang[44];
            break;
        case 'create':
            if (!props.info.join && props.mode === 'join') props.setMode('local');
            modalHeading = `You're about to play...`
            modalInfo = 
                <CreateGameMenu
                    collection={props.info.collection}
                    teams={props.teams}
                    editTeamName={props.editTeamName}
                    deleteTeam={props.deleteTeam}
                    updateTeams={props.updateTeams}
                    language={props.language}
                    addTeam={props.addTeam}
                    user={props.user}
                    mode={props.mode}
                    handleGameID={props.handleGameID}
                    handlePowerUpCheck={props.handlePowerUpCheck}
                    setMode={props.setMode}
                    join={props.info.join}
                    handleOptionCheck={props.handleOptionCheck}
                    handleOptionChange={props.handleOptionChange}
                    options={props.options}
                />
            modalFunc = props.teams[0].name.length ? () => props.start() : null;
            modalFuncLabel = lang[50];
            break;
        case 'share':
            modalHeading = 'Are you sure?'
            modalInfo =
                props.info ?
                    <div className="create-game">
                        <p className="create-game-title">You're about to share {props.info.title} with the world.</p>
                        <ul className="create-game-cards">
                            {props.info.cards ? 
                                <MenuCustomCollectionItem
                                    cards={props.info.cards}
                                />
                            :
                            <p className="create-game-title">It seems there are no cards...</p>
                            }
                        </ul>
                    </div>
                    : <p>Oops! Something went wrong.</p>
            modalFunc = () => props.share(props.info);
            modalFuncLabel = lang[44];
            break;
        case 'share-success':
            modalHeading= `${props.info.title} is shared!`
            modalInfo = 
                <>
                    <p>Anyone can now access {props.info.title} at</p>
                    <input value={`flipget.app/share#${props.info.id}`} disabled></input>
                </>
            modalFunc = () => setShow(false);
            modalFuncLabel = 'Got it';
            break;
        case 'login':
            modalHeading = 'Login'
            modalInfo = 
                <>
                    <input
                        key={5}
                        id="login-username"
                        type="username"
                        placeholder="Username"
                ></input>
                <input
                    key={6}
                    id="login-pass"
                    type="password"
                    placeholder="Password"
                    minLength={6}
                ></input>
                </>
            modalFunc = () => props.login();
            modalFuncLabel = 'Login';
            break;
        case 'new-account':
            modalHeading = 'Create a new account'
            modalInfo =
                <>
                    <p>flipGET was setup with students in mind. That's why you don't need to use a verifiable email. Anything that looks like an email, email@email.email, will work. Please choose a password that is at least 6 characters long and you don't use on any other website. Hope you enjoy the app!</p>
                    <input
                        key={9}
                        id="new-user-name"
                        type="username"
                        placeholder={lang[5]}
                    ></input>
                    <input
                        key={10}
                        id="new-user-pass"
                        type="password"
                        minLength={6}
                        placeholder={lang[6]}
                    ></input>
                </>
            modalFunc = disabled ? null : () => props.createAccount();
            modalFuncLabel = 'Create';
            break;
        case 'delete-account':
            modalHeading = 'Are you sure?'
            modalInfo = 
                <>
                    <p>This will permanently delete your account and all saved data. Type "delete" and press confirm.</p>
                    <input
                        id='delete-confirm'
                        placeholder='Type delete to confirm'
                    >
                    </input>
                </>
            modalFunc = () => props.deleteAccount();
            modalFuncLabel = 'Delete'
            break;
        default:
            return;
    }

    return (
            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={show}
                onHide={() => setShow(false)}
                onExited={() => props.cancelModal()}
                style={{
                    "paddingRight": '0'
                }}
                onKeyPress={handleEnterKey}
            >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    {modalHeading}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {modalInfo}
            </Modal.Body>
            <Modal.Footer>
            {props.loading.general || props.loading.game ?   
                <LoadingIndicator/>
                :
                <>
                <button className="cancel-button" onClick={() => setShow(false)}>{lang[35]}</button>
                { props.link ?
                    props.teams[0].name.length && props.info.collection && props.info.collection.cards.length ? <Link to={props.link}>
                        <button className="modal-button" onClick={handleClick}>{modalFuncLabel}</button>
                    </Link>
                    : <button className="modal-button disable">{modalFuncLabel}</button>
                    :
                    <button className="modal-button" disabled={disabled} onClick={handleClick}>{modalFuncLabel}</button>
                }
                </>
            }
            </Modal.Footer>
            </Modal>

    )
}

export default ModalMessage;
import React from 'react';
import styles from "./Update.module.css";

const Update = props => {
    return (
        <li className={styles.update}>
            <h4>
                {props.date}
            </h4>
            <ul className={styles.itemList}>
                {props.items.map((item, i) => {
                    return <li key={i}>
                            {item}
                        </li>
                })}
            </ul>
        </li>
    )
}

export default Update;
import React from 'react';

import CustomCollectionCard from './CustomCollectionCard/CustomCollectionCard';

function CustomCollectionCardInputs(props) {
    
    if (props.cards) {
        return props.cards.map( (card, index) => {
            return <CustomCollectionCard
            key = {card.id}
            id = {card.id}
            card={card}
            clicked = {() => props.clicked(index)}
            changed = {(event) => props.changed(event, card.id)}
            typeSelect = {props.typeSelect}
            />
        })
    } else {
        return null
    }
}

export default CustomCollectionCardInputs;
import React from 'react';

import './MainMenuButtons.css';

const MainMenuButtons = props => {

    const languageStrings = {
        
        en: {
          0: 'Local play',
          1: 'Join an online game',
          2: 'Host an online game',
          3: `Your game code: ${props.gameID}`,
          4: 'Back',
          5: 'Join',
          6: 'Game ID',
          7: 'Team name',
          8: 'Join!'
        },
    
        jp: {
          0: 'ローカルプレイ',
          1: 'オンラインゲームを参加',
          2: 'オンラインゲームを始まる',
          3: `あなたのオンラインゲームコード: ${props.gameID}`,
          4: '戻る',
          5: '参加',
          6: 'ゲームID',
          7: 'チーム',
          8: '参加'
        }
    }
    
    let lang = languageStrings[props.language];

    function handleOpen() {
      props.mode('join');
      props.displayModal('create', { join: true }, '/play');
    }

    return (
        <div className="menu-buttons">
            <button onClick={handleOpen}>{lang[8]}</button>
        </div>
    )
}

export default MainMenuButtons;
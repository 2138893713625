import React, { useState, useEffect } from 'react';


import './SuccessMessage.css';
import { Modal } from 'react-bootstrap';

const SuccessMessage = props => {

    // console.log('i have been called');

    let timeout = null;

    const [show, setShow] = useState(true);

    useEffect(() => {
        return () => {
            if (timeout) {
                clearTimeout(timeout);
            }
        }
    }, [timeout])
    if (!props.successes) return null;
    // console.log('successes', props.successes);
    const success = props.successes[0].success;
    const successInfo = props.successes[0].successInfo;

    let lang = props.language;

    let currentSuccessMessage;

    const successMessagesLib = {
        'en': {
            1: <ion-icon name="cloud-done-outline"></ion-icon>,
            2: <ion-icon name="cloud-download-outline"></ion-icon>,
            3: 'Ready to edit!',
            4: 'New account made. Welcome!',
            5: 'Welcome back!',
            6: 'See you soon!',
            7: <ion-icon name="person-add-outline"></ion-icon>,
            8: 'Deleted!',
            9: successInfo ? `${successInfo.attacker} shuffled ${successInfo.attacked}!` : null,
            10:<ion-icon name="person-remove-outline"></ion-icon>,
            11:<p className="successMessage">
                <ion-icon name="alert-outline"></ion-icon><br></br>
                {successInfo ? `${successInfo.attacker} used a skipAttack on ${successInfo.attacked}` : null}
                </p>,
            12:<p className="successMessage"> 
                <ion-icon name="play-forward-circle-outline"></ion-icon><br></br>
                Skipping!
                </p>,
            13: 'Match!',
            14: 'Oops! Try again.',
            15: `Next team!`,
            16: <p className="successMessage">
                <ion-icon name="skull-outline"></ion-icon><br></br>
                Sorry. The host kicked you.
            </p>,
            17: 'Go again!',
            18: 'flipGET',
            19: 'Password changed'
        },
        'jp': {
            1: <ion-icon name="cloud-done-outline"></ion-icon>,
            2: <ion-icon name="cloud-download-outline"></ion-icon>,
            3: '準備OK!',
            4: '新しいアカウントをつくりました。',
            5: '元気だった？',
            6: 'またねー',
            7: <ion-icon name="person-add-outline"></ion-icon>,
            8: '消しました。',
            9: 'シャフルアタックされました！',
            10:<ion-icon name="person-remove-outline"></ion-icon>,
            11:<p className="successMessage">
                <ion-icon name="alert-outline"></ion-icon><br></br>
                {successInfo ? `${successInfo.attacker} が ${successInfo.attacked}　にスキップアタックしました。` : null}
                </p>,
            12:<p className="successMessage"> 
                <ion-icon name="play-forward-circle-outline"></ion-icon><br></br>
                スキップ中
                </p>,
            13: 'ゲット！',
            14: '残念。がんばれ！',
            15: `次のチーム`,
            16: <p className="successMessage">
                <ion-icon name="skull-outline"></ion-icon><br></br>
                ホストがあなたをキックをしました。
            </p>,
            17: 'ナイス！',
            18: 'flipGET',
            19: '新しいパスワードを設定しました。'
        }
    }

    let successMessages = successMessagesLib[lang];

    switch (success) {
        case 'upload-success': 
            currentSuccessMessage = successMessages[1];
            break;
        case 'upload-found': 
            currentSuccessMessage = successMessages[2];
            break;
        case 'upload-editorSet': 
            currentSuccessMessage = successMessages[3];
            break;
        case 'account-new': 
            currentSuccessMessage = successMessages[4];
            break;
        case 'account-login': 
            currentSuccessMessage = successMessages[5];
            break;
        case 'account-logout':
            currentSuccessMessage = successMessages[6];
            break;
        case 'upload-delete':
            currentSuccessMessage = successMessages[8];
            break;
        case 'game-teamJoined':
            currentSuccessMessage = successMessages[7];
            break;
        case 'game-shuffleAttack':
            currentSuccessMessage = successMessages[9];
            break;
        case "game-teamDisconnect":
            currentSuccessMessage = successMessages[10];
            break;
        case "game-skipAttack":
            currentSuccessMessage = successMessages[11];
            break;
        case 'game-skip':
            currentSuccessMessage = successMessages[12];
            break;
        case 'game-match':
            currentSuccessMessage = successMessages[13];
            break;
        case 'game-mismatch':
            currentSuccessMessage = successMessages[14];
            break;
        case 'game-next':
            currentSuccessMessage = successMessages[15];
            break;
        case 'game-kicked':
            currentSuccessMessage = successMessages[16];
            break;
        case 'game-goAgain':
            currentSuccessMessage = successMessages[17];
            break;
        case 'pass-reset':
            currentSuccessMessage = successMessages[19];
            break;
        default: currentSuccessMessage = successMessages[18];
    }

    // console.log('i have been rendered', show);
    
    return (
        <Modal
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            show={show}
            backdrop={false}
            onShow={() => timeout = setTimeout(() => setShow(false), 1500)}
            onExited={() => {
                // console.log('i have exited');
                props.resolveSuccess();
                if (props.successes[1]) setShow(true);
            }}
            style={{
                "padding": '0'
            }}
        >
            <Modal.Body>
                    <div className="successMessage">{currentSuccessMessage}</div>
            </Modal.Body>
        </Modal>
    )
}

export default SuccessMessage;
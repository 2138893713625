import React from 'react';


import Input from "../Input/Input";
import Card from "../../Card/Card";
const types = {
    1: "text",
    2: "image",
    3: "video",
    4: "audio"
}
const customCollectionCard = props => {
    return (
    <div className="custom-collection-card">
        <div className="delete-but-div">
            <button className="delete-but" onClick={props.clicked}>
                <ion-icon name="close-outline"></ion-icon>
            </button>
        </div>
        <div>
            {Object.keys(props.card.content).map(num => {
                return <Input
                    key={num}
                    for={"content"}
                    num={num}
                    type={props.card.content[num].contentType}
                    content={props.card.content[num].content}
                    changed={props.changed}
                />
            })}
        </div>
        <div className="preview-box">
        {Object.keys(props.card.content).map(num => {
                return <Card
                key={num}
                isFlipped={true}
                onClick={null}
                id={num}
                content={props.card.content[num].content}
                type={props.card.content[num].contentType}
                autoplay={false}
                dStatus={3}
            />
            })}
        </div>
        <div className="card-options-div">
            {Object.keys(props.card.content).map(num => {
                return <div key={num}>
                    <label htmlFor={`card-type-select-${num}`}>
                        Card type {num}
                    </label>
                    <select name={`card-type-${num}`} id={`card-type-select-${num}`} data-num={num} data-for="contentType" onChange={(e) => props.changed(e, props.id)} value={props.card.content[num].contentType}>
                        {Object.keys(types).map(type => {
                            return (
                                <option key={type} name={`card-type-${props.id}-${num}`} id={`card-type-${type}-${props.id}-${num}`} value={type}>
                                    {types[type]}
                                </option>
                            )
                        })
                        
                        }
                    </select>
                </div>
            })}
            
        </div>
    </div>
    )
}

export default customCollectionCard;

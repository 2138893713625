class CustomCollectionClass {
    constructor(title, cards, id) {
        this.title = title;
        this.cards = cards;
        this.id = id;
    }
}

export default CustomCollectionClass;


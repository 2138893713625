import React from 'react';



import './LoadingIndicator.css';

const LoadingIndicator = props => {
    
    return (
        <div className="loading-spinner">
          <div className="opaqueScreen-loader">
            {/* <Loader type="Grid" color="#2ecc71" height="15vw" width="15vw" /> */}
            <div className="grid-loader">
              <div className="grid-loader-square first"></div>
              <div className="grid-loader-square second"></div>
              <div className="grid-loader-square third"></div>
              <div className="grid-loader-square second"></div>
              <div className="grid-loader-square third"></div>
              <div className="grid-loader-square fourth"></div>
              <div className="grid-loader-square third"></div>
              <div className="grid-loader-square fourth"></div>
              <div className="grid-loader-square fifth"></div>
            </div>
          </div>
        </div>
    );
}

export default LoadingIndicator;
import React, { Component } from 'react';
import './CustomCollection.css';
import CustomCollectionCardInputs from '../CustomCollectionCardInputs/CustomCollectionCardInputs';
import styled from 'styled-components';
import LoadingIndicator from '../../hoc/LoadingIndicator';
import CustomCollectionClass from '../../lib/customCollectionClass';
import {Button} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import uniqid from 'uniqid';
import { createBrowserHistory } from 'history';
import { appVersion } from "../../functions/appVersion";
let history = createBrowserHistory();

const StyledWrapper = styled.div`
@media (max-width: 900px) and (min-width: 500px) {
    .custom-collection-card,
    .add-card-outline {
      width: 49%;
      margin: .5%;
    }

    .ReturnMainMenuBtn {
        width: 8vw;
        height: 8vw;
        font-size: 2vw;
    }
}

@media (max-width: 499px) {
    .custom-collection-card,
    .add-card-outline {
        display:grid;
        width: 99%;
        margin: .5%;
    }

    .ReturnMainMenuBtn {
        width: 10vw;
        height: 10vw;
        font-size: 3vw;
    }
}

`

class CustomCollection extends Component {

  constructor(props) {
      super(props)
      this.state = {
          loading: {
              page: true,
              cloud: false
          }
      }
  }

  componentDidMount = () => {
    const id = window.location.hash.replace('#', '');
    // console.log({id});
    if (id && id !== this.props.id && id !== 'new') {
      // console.log('setting up app before doing work here');
      return this.props.setInEditor(id);
    }    

    if (this.props.mode === 'edit') {
      this.setCloudSave(this.props.id);
    } else {
      this.setNew();
    }
  }

  componentDidUpdate = (prevProps) => {
    const id = window.location.hash.replace('#', '');
    if (id && id !== this.props.id) {
      // console.log('hash doesnt match props id');
      return this.props.setInEditor(id);
    }   
    
    if (prevProps.id !== this.props.id || (this.props.update && this.props.update !== prevProps.update)) {
      // console.log('previous props id doesnt match current props id');
      if (this.props.mode === 'edit') {
          this.setCloudSave(this.props.id);
      } else {
        this.setNew();
      }
    }

    window.onpopstate = this.handleBrowserButton;
  }

  componentWillUnmount= () => {
    this.props.cleanup();
    window.removeEventListener('popstate', this.handleBrowserButton);
  }

  //CUSTOM COLLECTION EDITOR

  //LOADING

  toggleLoading = (type, state) => {
    const loading = {...this.state.loading}
    // console.log(type);
    loading[type] = state;
    
    this.setState({loading: loading});
  }

  //INITIALIZATION

  setNew = () => {
    const collection = new CustomCollectionClass('', [], uniqid());

    this.setState({collection: collection});

    if (this.state.loading.page) {
        this.toggleLoading('page', false);
    }
  }

  setCloudSave = id => {
    if (!this.props.cloudSaves || !this.props.cloudSaves.length) return setTimeout(() => this.setCloudSave(id), 200);
    this.props.setUpdate(false);
    const cs = [...this.props.cloudSaves];
    // console.log(cs);
    const selectedCS = {...cs.find(s => s.id === id)};
    // console.log(selectedCS);
    
    if (selectedCS.title) {
      this.setState({
        collection: selectedCS,
      });
    } else {
      // this.props.cleanup();
      this.props.error('user-no-collections');
      this.setNew();
      history.push('/editor#new');
    }

    if (this.state.loading.page) {
        this.toggleLoading('page', false);
    }

    window.scrollTo(0, 0);
    
  }

  titleChangedHandler = (event) => {
    const collection = {...this.state.collection}

    collection.title = event.target.value;
  
    this.setState({collection: collection});
  }

  cardsChangedHandler = (event, id) => {
    const collection = {...this.state.collection}

    const cards = collection.cards;

    const cardIndex = cards.findIndex(card => {
        return card.id === id;
    })
    
    const card = cards[cardIndex]
    // console.log(card);
    // console.log(event);
    // console.log(event.target.dataset.for, event.target.value);
    // console.log(event.target.type);

    if (event.target.type === "url" && card.content[event.target.dataset.num].contentType === 3) {
      // console.log(event.target.value.replace('watch?v=', 'embed/'));
      card.content[event.target.dataset.num][event.target.dataset.for] = event.target.value.replace('watch?v=', 'embed/');
    } else {
      card.content[event.target.dataset.num][event.target.dataset.for] = parseInt(event.target.value) ? parseInt(event.target.value) : event.target.value;
    }
    
    this.setState({collection: collection});
  }

  deleteCardHandler = (cardIndex) => {
    const collection = {...this.state.collection}

    const cards = collection.cards;

    cards.splice(cardIndex, 1);
    collection.cards = cards;
    this.setState({ collection: collection });
  }

  addCardHandler = (info) => {
    const collection = {...this.state.collection}

    const cards = collection.cards;
    cards.push({
      id:uniqid(),
      content: {
        1: {
          content: info.text ? info.text : '',
          contentType: 1,
        },
        2: {
          content: info.imageURL ? info.imageURL : '',
          contentType: 2
        },
      } 
    });

    // console.log(cards);

    this.setState({ collection: collection });
  }

  copy = async id => {

    this.toggleLoading('cloud', true);
    const cs = [...this.props.cloudSaves];

    const csInd = cs.findIndex(cs => {
      return cs.id === id
    })

    const selectedCS = cs[csInd];

    const selectedCSCopy = {...selectedCS};

    try {

      if (await this.props.exists(this.props.user.id, selectedCS.id)) {

        do {
          selectedCSCopy.title = `${selectedCS.title} copy`;
          selectedCSCopy.createdOn = Date.now();
          selectedCSCopy.lastEdit = Date.now();
          selectedCSCopy.id = uniqid();
          if (selectedCSCopy.shareID) selectedCSCopy.shareID = null;

          // console.log('checking for duplicate')
        } while (await this.props.exists(this.props.user.id, selectedCSCopy.id))
      }

      await this.props.setFirebaseDocument(this.props.user.id, selectedCSCopy);

      // console.log('saved')
  
      this.toggleLoading('cloud', false);
      this.props.setInEditor(selectedCSCopy.id);
      return history.push(`/editor#${selectedCSCopy.id}`);
    } catch (err) {
      this.props.error(err);
    }

  }

  submit = async () => {

    this.toggleLoading('cloud', true);

    const collection = {...this.state.collection};
    // console.log(collection);

    collection.lastEdit = Date.now();
    collection.appVersion = appVersion();

    if (!collection.createdOn) {
      collection.createdOn = Date.now();
    }

    if (collection.cards.length >= 1 && this.props.user.id) {
        await this.props.setFirebaseDocument(this.props.user.id, collection);
        history.push(`/editor#${collection.id}`)
    } else {
        if (!collection.title || collection.cards.length < 1) {
            this.props.error('input/incorrect-arguments');
        } else if (!this.props.user.id) {
            this.props.error('auth/no-user');
        }
    }
    this.toggleLoading('cloud', false);
    
  }

  //BROWSER

  handleBrowserButton = () => {
    const id = window.location.hash.replace('#', '');
    // console.log('custom back button');
    if (id) {
      this.props.setInEditor(id);
    } else {
      this.props.cleanup();
      const collection = {...this.state.collection};
      collection.title = '';
      this.setState({collection: collection});
    }
  }

    render() {



        const languageStrings = {
        
            en: {
                0: 'Editor',
                1: 'card',
                2: 'Title',
                3: 'Back',
            },
        
            jp: {
                0: '編集',
                1: 'カード',
                2: 'タイトル',
                3: '戻る',
            }
        }
        
        let lang = languageStrings[this.props.language];

            return (
                this.state.loading.page ?
                    <LoadingIndicator/>
                :
                    <StyledWrapper>
                    <div className="editor-menu-box">
                        <div className="editor-box">
                        <div className="return-but-box">
                          <Link to="/">
                                <button className="return-button" onClick={() => this.props.setPage('Menu')}>
                                    <ion-icon name="chevron-back-outline"></ion-icon>
                                </button>
                          </Link>
                        </div>
                            <input className="custom-collection-title" id="title" type="text" name="title"
                                onChange={(event) => this.titleChangedHandler(event)}
                                value={this.state.collection.title}
                                placeholder="Title"
                                maxLength={60}
                                >
                            </input>
                            {this.state.collection.shareID ? <p>Shared globally at flipget.app/share#{this.state.collection.shareID}</p> : null}
                            <div className="collection-edit-buttons-box">
                              <Button 
                                disabled={this.state.collection.createdOn ? false : true} 
                                onClick={() => this.copy(this.state.collection.id)}
                                >
                                  Copy
                                </Button>
                            </div>
                            <div className="custom-collection-box">
                                <div className="custom-collection-inner">
                                    <CustomCollectionCardInputs 
                                    cards={this.state.collection.cards} 
                                    clicked={this.deleteCardHandler} 
                                    changed={this.cardsChangedHandler}
                                    />
                                    <div className="add-card-outline">
                                        <button className="addCardBtn" onClick={this.addCardHandler}>+<br></br>{lang[1]}</button>
                                    </div>
                                </div>
                            </div>
                            {this.state.loading.cloud ? 
                            <LoadingIndicator/>
                            :
                            <button className="submitCustomCollectionBtn" onClick={this.submit}>
                                <ion-icon name="cloud-upload-outline"></ion-icon>
                            </button>
                            }
                        </div>
                        <div className="editor-information">
                          <div>
                              <h5>
                                Having trouble with audio clips or videos?
                              </h5>
                              <p>
                                As of December 2021, only YouTube video links will work. Copy and paste the URL from your browser or right click a YouTube video and click "Copy Video URL." Audio clips must be direct links (i.e., those links must end in ".mp3" or some other audio format for them to work). If you want a simple solution for using your own custom audio, I recommend Dropbox. On the phone app version of Dropbox, you can easily record audio and then get the share link. You'll need to change the share link slightly as described in <a rel="noopener noreferrer" href="https://zapier.com/learn/how-to/generate-direct-dropbox-link/" target="_blank">this article</a>. As always, if you have any issues, feel free to <a href="mailto:flipget.contact@gmail.com">email</a>!
                              </p>
                            </div>
                        </div>
                    </div>
                    </StyledWrapper>
                );
    }
};

export default CustomCollection;
import React, { Component } from "react";
import "./App.css";
import Board from "../components/Board/Board";
import CustomCollection from "../components/CustomCollection/CustomCollection";
import ErrorMessage from '../components/ErrorMessage/ErrorMessage';
import SuccessMessage from '../components/SuccessMessage/SuccessMessage';
import ModalMessage from '../hoc/ModalMessage/ModalMessage';
import CloudSavesMenu from '../components/CloudSavesMenu/CloudSavesMenu';
import uniqid from 'uniqid';
import { trackPromise } from 'react-promise-tracker';
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth'
import './App.css';
import simpleShareID from '../functions/simpleShareID';
import LoadingIndicator from "../hoc/LoadingIndicator";
import Header from '../components/Header/Header';
import { createBrowserHistory } from 'history';
import { appVersion } from "../functions/appVersion";
import {updateCards} from '../functions/september21UpdateCheck';
import Updates from "../components/Updates/Updates";
import Contact from "../components/Contact/Contact";
import PrivacyPolicy from "../components/PrivacyPolicy/PrivacyPolicy";
import HowTo from "../components/HowTo/HowTo";
import About from "../components/About/About";
let history = createBrowserHistory();

const firebaseConfig = {
    apiKey: "AIzaSyD9ms90-4F5Gt7Y4q9Th8xBIXVzcPDgJ3k",
    authDomain: "concentration-fd873.firebaseapp.com",
    databaseURL: "https://concentration-fd873.firebaseio.com",
    projectId: "concentration-fd873",
    storageBucket: "concentration-fd873.appspot.com",
    messagingSenderId: "1042268620649",
    appId: "1:1042268620649:web:5d2ca31d6ec85e99949ff8",
    measurementId: "G-Z50F9LR1DR"
};
  
// Initialize Firebase
const firebaseApp = firebase.initializeApp(firebaseConfig);
const fire = firebase.firestore;
const db = firebaseApp.firestore();

//TODO: include document and shared collection deletion in user deletion

class App extends Component {
  constructor(props) {
    super(props);
    this.state= {
      language: 'en',
      userPassword: null,
      userEmail: null,
      user: null,
      editor: {
        mode: 'new',
        id: 'new',
      },
      creatingNewAccount: false,
      loading: {
        general: false,
        game: false,
        batch: false
      },
      game: {
        teams: [],
        mode: 'local',
        collection: null,
        flipAttacks: {
          shuffle: true,
          skip: true
        },
        options: {
          autoskip: {
            isOn: true,
            time: 10
          }
        }
      }
    };
  }

  componentDidMount() {
    this.getUserInfo();

    this.addTeamHandler();
  }

  componentDidUpdate(prevProps, prevState) {

    if (prevState.page !== this.state.page) {
      if (prevState.page === 'Board') {
        this.cleanupBoard();
      } else if (prevState.page === 'Custom Collection') {
        this.cleanupCustomCollection();
      }
    }

    if (this.state.waitingForUser && this.state.user && this.state.user !== prevState.user) {
      this.state.delayedFunction();
    }

  }

  //CLEANUP

  cleanupCustomCollection = () => {
    const editor = {...this.state.editor};

    editor.id = 'new';
    editor.mode = 'new';
    
    this.setState({
      editor: editor,
    });

    window.scrollTo(0, 0);
  }

  cleanupBoard = () => {
    const teams = [];

    this.state.game.teams.map(item => {
      const team = {
        name: '',
        id: uniqid(),
        points: 0,
        matchedCards: [],
        usingCards: 'og',
        flipAttacks: {
            shuffle: false,
            skip: true
        },
        shouldSkip: 0
      };
      
      team.name = item.name;
      return teams.push(team);
    });

    // console.log(teams);

    this.setState({
      game: {
        teams: teams,
        mode: 'local',
        collection: null,
        flipAttacks: {
          shuffle: true,
          skip: true
        },
        options: {
          autoskip: {
            isOn: true,
            time: 10
          }
        }
      }
    });
    
  }

  //EDITOR

  setCloudSaveInCustomCollectionEditor = id => {
    const editor = {...this.state.editor};
    // console.log('collection', id);
    editor.id = id;
    editor.mode = id === 'new' ? 'new' : 'edit';
    
    this.setState({editor: editor});
    this.setPage('Custom Collection');
  }

  setUpdate = state => {
    const editor = {...this.state.editor};

    editor.update = state;
    this.setState({
      editor: editor
    })
  }

  //CLOUD SAVES

  getCloudSaves = async (user) => {

    this.toggleLoading('general', true);
      
    const userRef = db.collection(user.id);
      
    const unsubscribeFromCloudSaves = userRef.onSnapshot(async collections => {
      if (this.state.loading.batch) {
        return;
      }
      
      if (!collections.docs) {
        this.setState({error: 'user-no-collections'});
        this.toggleLoading('general', false);
        return;
      }

      const cloudSaves = [];
      
      collections.docs.forEach(async item => {
        const col = {...item.data()};
        let finalCol;
        
        if (col.appVersion >= appVersion()) {
          finalCol = col;
        } else {
          if (!this.state.loading.batch) this.toggleLoading("batch", true);
          finalCol = await this.september21UpdateCheck(col, user.id);
        }

        return cloudSaves.push(finalCol);
      });

      if (this.state.loading.batch) this.toggleLoading('batch', false);

      user.cloudSaves = [...cloudSaves];
      this.setState({
        user: user,
        success: 'upload-found',
        unsubscribeFromCloudSaves: unsubscribeFromCloudSaves,
        editor: {
          update: true
        }
      });
    }, () => {

      this.setState({error: 'auth/network-request-failed'});
    });
    this.toggleLoading('general', false);
  };

  getFirebaseDocument = async (id, collection) => {

    const userRef = db.collection(collection).doc(id);

    return userRef.get()
    .then(doc => {
      // console.log(doc);
      if (doc.exists) {
        return doc.data();
      } else {
        //throw some error
        this.setError('user-no-collections')
      }
    })
    .catch((err) => this.setError(err));

  }

  september21UpdateCheck = async (col, userID) => {
    let finalCol = col;

    if (!col.appVersion || col.appVersion < appVersion()) {
        finalCol = updateCards(col);
        finalCol.appVersion = appVersion();
        // console.log('updating collection', finalCol);
        if (userID) {
          await this.setFirebaseDocument(userID, finalCol);
          if (finalCol.shareID) await this.handleShare(finalCol, finalCol.shareID);
        };
    }
    // console.log('return');
    return finalCol;
  }

  //ACCOUNT MANAGEMENT

  getUserInfo = async () => {
    this.toggleLoading('general', true);

    firebase.auth().onAuthStateChanged(async (user) => {
      // console.log(user);
      if (user) {
        const userObj = {
          displayName: user.email.split('@')[0],
          id: user.uid,
          cloudSaves: []
        }

        this.setState({
          user: userObj
        });

        if (!this.state.unsubscribeFromCloudSaves) {
          // console.log('getting cloud saves');
          await this.getCloudSaves(userObj);
        }
        
      } else {
        this.setState({user: null});
      }
    });
    return this.toggleLoading('general', false);
}

  createNewUser = () => {
    this.toggleLoading('general', true);
    trackPromise(
    firebase.auth().createUserWithEmailAndPassword(document.getElementById('new-user-name').value, document.getElementById('new-user-pass').value)
    .then(() => {
      // console.log('Success! User created with email: ', this.state.newUserEmail);
      this.setState({
        success:'account-new'
      });
    })
    .catch(error => {
      // Handle Errors here.
      // console.log(error.code);
      this.setState({
        error: error.code});
        return this.toggleLoading('general', false);
      // ...
    }));
    this.getUserInfo();
  }

  reauthenticateUser = () => {
    var user = firebase.auth().currentUser;
    const email = user.email;
    const pass = document.getElementById('reauth-pass-input').value;
    var credential = firebase.auth.EmailAuthProvider.credential(email, pass);

    // Prompt the user to re-provide their sign-in credentials

    user.reauthenticateWithCredential(credential).then(() => {
      // User re-authenticated.
      this.setState({success: 'account-login'});
    }).catch((error) => {
      // An error happened.
      if (error.code === 'auth/wrong-password') {
        this.setState({error:'auth/wrong-password'})
      } else {
        this.setError(error);
      }
    });
  }

  changePass = () => {
    const user = firebase.auth().currentUser;
    const newPassword = document.getElementById('change-pass-input').value;
    const newPasswordConfirm = document.getElementById('change-pass-input-confirm').value;

    if (newPassword !== newPasswordConfirm) {
      this.setState({error: 'pass-mismatch'});
      return;
    } else if (newPassword.length < 6) {
      this.setState({error: 'pass-too-short'});
      return;
    }

    user.updatePassword(newPassword).then(() => {
      // Update successful.
      this.setState({success: 'pass-reset'});
    }).catch((error) => {
      // An error happened.
      if (error.code === 'auth/requires-recent-login') {
        this.displayModal('user-reauth');
      } else {
        this.setError(error);
      }
    });
  }

  signUserOut = () => {
    firebase.auth().signOut().then( () => {
      this.state.unsubscribeFromCloudSaves();
      this.cleanupCustomCollection();
      
      this.setState({
        user: null,
        success:'account-logout',
        unsubscribeFromCloudSaves: null,
      });

      this.setPage('Menu');
    }).catch(function(error) {
      // console.log(error.code);
      this.setState({error: error.code});
    });
  }

  setCurrentUser = async () => {
    this.toggleLoading('general', true);
  try {
        await firebase.auth().signInWithEmailAndPassword(document.getElementById('login-username').value, document.getElementById('login-pass').value);
        
        this.setState({
          success: 'account-login',
        });
      } catch (error) {
        this.setState({
          error: error.code,
        });
    }

    this.toggleLoading('general', false);
    
  }

  deleteUser = async () => {
    const user = firebase.auth().currentUser;

    if (document.getElementById('delete-confirm').value !== 'delete' || !user) {
      return;
    }

    if (this.state.user.cloudSaves.length) {
      await Promise.all(
        this.state.user.cloudSaves.map(async cs => {
          if (cs.shareID) await this.deleteDocumentFromFirebase('share', cs.shareID);
          await this.deleteDocumentFromFirebase(this.state.user.id, cs.id);
        })
      )
    }
    

    user.delete().then(() => {
      this.setState({
        user: null,
        success:'upload-delete',
        unsubscribeFromCloudSaves: null,
      });
    }).catch(err => {
      if (err.code === 'auth/requires-recent-login') {
        this.displayModal('user-reauth');
      } else {
        this.setError(err);
      }
    })
  }

  doesFirebaseDocumentExist = async (collection, id) => {
    const ref = db.collection(collection).doc(id);

    return (await ref.get()).exists;
  }
 
  setFirebaseDocument = async (collection, object) => {    
    const userRef = db.collection(collection).doc(object.id);
  
    await userRef.set(object, function(err) {
      if (err) {
        this.setError(err);
        return false;
      } else {
        this.setState({
          success:'upload-success',
        });
        return true;
      }
    });
  }

  deleteDocumentFromFirebase = async (collection, id) => {

    if (collection === 'share') {
      const col = this.state.user.cloudSaves.find(cs => cs.shareID === id)
      col.shareID = null;
      await this.setFirebaseDocument(this.state.user.id, col);
    } else if (this.state.user && collection === this.state.user.id && this.state.user.cloudSaves.find(cs => cs.id === id).shareID) {
      await this.deleteDocumentFromFirebase('share', this.state.user.cloudSaves.find(cs => cs.id === id).shareID);
    }

    let ref = db.collection(collection).doc(id);

    ref.delete()
    .then(() => {
      this.setState({success: 'upload-delete'});
      if (this.state.editor.id === id) {
        this.cleanupCustomCollection();
        history.push('/editor#new');
      }
    })
    .catch(() => {
      this.setState({error:'auth/network-request-failed'});
    });
    
  }

  //MODALS AND OTHER FEEDBACK

  displayModal = (type, info, link) => {
    this.setState({
      modal: type, 
      modalInfo: info,
      modalLink: link
    });
  }

  setSuccess = (message, info) => {
    this.setState({
      success: message,
      successInfo: info,
    })
  }

  resolveSuccess = () => {

    this.setState({
      success:null
    });
  }
  
  dismissSuccess = () => {
    if (this.state.success) {
      this.setState({
        success: null
      });
    } else {
      return;
    }
  }

  cancelModal = () => {
    this.setState({
      modal: null,
      modalInfo: null
    });
  }

  setError = type => {
    this.setState({error: type})
  }

  resolveError = () => {
    this.setState({error: null});
  }

  setLanguage = lang => {
    let curLang = this.state.language;
    if (curLang === lang) {
      return;
    } else {
      this.setState({language: lang});
    }
  }

  toggleDisplayCreateUserMenu = () => {
    let prev = this.state.creatingNewAccount;
    this.setState({creatingNewAccount: !prev});
  }

  toggleLoading = (type, state) => {

    const loading = {...this.state.loading};

    loading[type] = state;

    this.setState({loading: loading});
  }

  //NAVIGATION

  setPage = (page) => {
    this.setState({page: page});
    // console.log(this.state.page);
  }

  //GAME CREATION
  handleGameCreateOpen = async (id, link, path) => {
    // console.log('called game create open');
    if (this.state.game.mode === 'join') this.setMode('local');
    if (path !== '/share' && !this.state.user) {
      this.setState({
        waitingForUser: true,
        delayedFunction: () => this.handleGameCreateOpen(id, link, path)
      });
      if (!this.state.gameCreateTimeout) {
        const gameCreateTimeout = setTimeout(() => {
          // console.log('failed after 10 sec');
          this.setError('auth/no-user');
          this.setState({waitingForUser: false});
        }, 10000);
        return this.setState({
          gameCreateTimeout: gameCreateTimeout,
        });
      }
      return;
    }

    clearTimeout(this.state.gameCreateTimeout);
    if (this.state.waitingForUser) this.setState({
      waitingForUser: false,
      delayedFunction: null
    })
    let col = this.state.user ? this.state.user.cloudSaves.find(c => c.id === id) : null;
    const colPath = path && path.includes('share') ? 'share' : this.state.user.id
    if (!col) col = await this.getFirebaseDocument(id, colPath.replace('/', ''));
    // console.log(col);
    if (!col) return this.setError('game-noGameFound');

    const finalCol = col.appVersion >= appVersion() ? col : await this.september21UpdateCheck(col);

    const info = {
      collection: finalCol
    };
    this.displayModal('create', info, link);
    this.setCollection(finalCol.id);
  }

  handlePowerUpCheck = (e) => {
    // console.log(e);

    const game = {...this.state.game};

    game.flipAttacks[e.target.id] = e.target.checked;

    this.setState({game: game});
  }

  handleOptionCheck = e => {
    const game = {...this.state.game};
    game.options[e.target.id].isOn = e.target.checked;
    this.setState({game: game});
  }

  handleOptionChange = e => {
    const game = {...this.state.game};
    game.options[e.target.id.split("_")[0]][e.target.id.split("_")[1]] = parseInt(e.target.value);
    this.setState({game: game});
  }

  setCollection = async (id) => {
    this.toggleLoading('game', true);
    const collection = id ? id : document.querySelector('#game-id').value;

    // console.log(collection);
    const game = {...this.state.game};
    game.collection = collection;
    
    this.setState({
      game: game
    });

    // this.setPage('Board');
    this.toggleLoading('game', false);
  }

  setMode = async (mode) => {
    const game = {...this.state.game};
    game.mode = mode;
    // console.log(mode);
    this.setState({
      game: game
    });
  }

  startGame = () => {
    this.setPage('Board');
  }

  handleShare = async (col, colID) => {
    let id;
    const savedID = col.id;

    if (!colID) {
      do {
        id = simpleShareID();
      } while (await this.doesFirebaseDocumentExist('share', id))
    } else {
      id = colID;
    }
    
    //add to the share firebase collection
    col.id = id;
    try {
      await this.setFirebaseDocument('share', col);
      col.id = savedID;
      col.shareID = id;
      await this.setFirebaseDocument(this.state.user.id, col);
      this.displayModal('share-success', {id: col.shareID, title: col.title});
    } catch (e) {
      this.setError(e);
    } 
  }

  teamNameInputChangedHandler = (event, id) => {

    const game = {...this.state.game}
    
    const teams = game.teams;

    // console.log(teams);

    const ind = teams.findIndex(team => {
      return team.id === id;
    })

    const selectedTeam = teams[ind];

    selectedTeam.name = event.target.value;

    // console.log(selectedTeam.name);

    this.setState({
      game: game
    });
  }

  addTeamHandler = () => {
    const game = {...this.state.game}
    
    const teams = game.teams;

    const team = {
      name: '',
      id: uniqid(),
    };

    teams.push(team);

    this.setState({
      game: game
    });
  }

  deleteTeamHandler = index => {
    const game = {...this.state.game}

    if (game.teams.length === 1) {
      return;
    }

    game.teams.splice(index, 1);

    this.setState({
      game: game
    });
  }

  updateTeams = (dragInd, dropInd) => {
    const game = {...this.state.game};

    // console.log({dragInd, dropInd})

    game.teams.splice(dropInd, 0, game.teams.splice(dragInd, 1)[0]);
    // console.log(game.teams);
    
    this.setState({game: game});
  }

  handleGameIDInput = async e => {
    e.persist();
    const value = e.target.value.trim().toLowerCase();
    const modalInfo = {...this.state.modalInfo};
    if (value.length < 3) {
      if (this.state.game.collection) this.setCollection(null);
      if (this.state.modalInfo.collection) {
        modalInfo.collection = null;
        this.setState({modalInfo: modalInfo});
      }
      return;
    };

    // console.log(e.target.value);

    if (await this.doesFirebaseDocumentExist('games', value)) {
      const col = await this.getFirebaseDocument(value, 'games');
      modalInfo.collection = {
        title: col.title + '!',
        cards: col.structuredCards
      };
      this.setCollection(value);
    } else {
      modalInfo.collection = {
        title: 'No game found.',
        cards: []
      };
    }

    this.setState({modalInfo: modalInfo});
  }

  render() {
    return (
      <Router>
        <div key={'page'} className="app" onClick={() => this.dismissSuccess()}>
          <Header
            user={this.state.user}
            signUserOut={this.signUserOut}
            language={this.state.language}
            displayModal={this.displayModal}
            loading={this.state.loading.general}
            mode={this.setMode}
            version={this.state.version}
          />
          {this.state.error ? 
          <ErrorMessage 
            key={'error-message'}
            error={this.state.error}
            clicked={this.resolveError}
            language={this.state.language}
          />
          : null}
          {this.state.success ? <SuccessMessage
            key={'success-message'} 
            success={this.state.success}
            successInfo={this.state.successInfo}
            resolveSuccess={this.resolveSuccess}
            language={this.state.language}
          />
          : null }
          {this.state.modal ?
          <>
          <ModalMessage
            modal={this.state.modal}
            language={this.state.language}
            deleteCollection={this.deleteDocumentFromFirebase}
            info={this.state.modalInfo}
            cancelModal={this.cancelModal}
            changePass={this.changePass}
            reauthenticateUser={this.reauthenticateUser}
            setMode={this.setMode}
            setCollection={this.setCollection}
            handlePowerUpCheck={this.handlePowerUpCheck}
            setPage={this.setPage}
            loading={this.state.loading}
            returnToMainMenu={this.leaveGame}
            teams={this.state.game.teams}
            editTeamName={this.teamNameInputChangedHandler}
            deleteTeam={this.deleteTeamHandler}
            addTeam={this.addTeamHandler}
            updateTeams={this.updateTeams}
            mode={this.state.game.mode}
            link={this.state.modalLink}
            user={this.state.user}
            get={this.getFirebaseDocument}
            handleGameID={this.handleGameIDInput}
            start={this.startGame}
            share={this.handleShare}
            login={this.setCurrentUser}
            createAccount={this.createNewUser}
            deleteAccount={this.deleteUser}
            handleOptionChange={this.handleOptionChange}
            handleOptionCheck={this.handleOptionCheck}
            options={this.state.game.options}
          />
          </>
          : null}
          <div className="content">
          <Switch>
            <Route path="/updates">
              <Updates/>
            </Route>
            <Route path="/how-to">
              <HowTo/>
            </Route>
            <Route path="/about">
              <About/>
            </Route>
            <Route path="/contact">
              <Contact/>
            </Route>
            <Route path="/privacy">
              <PrivacyPolicy/>
            </Route>
            <Route path="/editor">
              <CustomCollection
                setPage={this.setPage}
                exists={this.doesFirebaseDocumentExist}
                mode={this.state.editor.mode}
                id={this.state.editor.id}
                language={this.state.language}
                cleanup={this.cleanupCustomCollection}
                cloudSaves={this.state.user ? this.state.user.cloudSaves : null}
                user={this.state.user}
                error={this.setError}
                setFirebaseDocument={this.setFirebaseDocument}
                setInEditor={this.setCloudSaveInCustomCollectionEditor}
                setUpdate={this.setUpdate}
              />
            </Route>
            <Route path={["/play", "/share"]}>
              <Board 
                teams={this.state.game.teams}
                flipAttacks={this.state.game.flipAttacks}
                setPage={this.setPage}
                page={this.state.page}
                set={this.setFirebaseDocument}
                language={this.state.language}
                mode={this.state.game.mode}
                id={this.state.game.collection}
                exists={this.doesFirebaseDocumentExist}
                isBusy={this.isDatabaseObjectBusy}
                db={db}
                fire={fire}
                user={this.state.user}
                success={this.setSuccess}
                dismissSuccess={this.dismissSuccess}
                modal={this.displayModal}
                deleteDocumentFromFirebase={this.deleteDocumentFromFirebase}
                error={this.setError}
                getUserInfo={this.getUserInfo}
                setAppObject={this.setMode}
                handleOpenMenu={this.handleGameCreateOpen}
                getFirebaseDocument={this.getFirebaseDocument}
                september21UpdateCheck={this.september21UpdateCheck}
                options={this.state.game.options}
              />
            </Route>
            <Route path="/">
            </Route>
          </Switch>
            <div className="cloud-saves-menu-box"> 
              {this.state.loading.general ? 
                <LoadingIndicator></LoadingIndicator>
                : 
                    <CloudSavesMenu
                      user={this.state.user} 
                      getCloudSaves={this.getCloudSaves}
                      deleteDocumentFromFirebase={this.deleteDocumentFromFirebase}
                      language={this.state.language}
                      setPage={this.setPage}
                      edit={this.setCloudSaveInCustomCollectionEditor}
                      resolveError={this.resolveError}
                      loading={this.state.loading.general}
                      displayModal={this.displayModal}
                      show={true}
                      cleanup={this.cleanupCustomCollection}
                      modal={this.displayModal}
                      openGameMenu={this.handleGameCreateOpen}
                      setMode={this.setMode}
                      mode={this.state.game.mode}
                    />
                }
            </div>
          </div>
        {/* <div key={'language'} className='language-pane'>
          <button className={this.state.language === 'en' ? "language-btn-selected" : "language-btn"} onClick={() => this.setLanguage('en')}>English</button>
          <button className={this.state.language === 'jp' ? "language-btn-selected" : "language-btn"} onClick={() => this.setLanguage('jp')}>日本語</button>
        </div> */}
        {/* {this.state.page !== 'Board' ? <Footer/> : null} */}
        </div>
      </Router>
    )    
  }
}
  

export default App;

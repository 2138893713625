import React, { useRef } from 'react';

import './Team.css';
import { Button } from 'react-bootstrap';

const Team = props => {

    const ref = useRef();

    const languageStrings = {
        
        en: {
            0: 'Team name',
        },
    
        jp: {
          0:'チーム'
        }
      }
    
    let lang = languageStrings[props.language];

    return (
        <div 
            data-ind={props.index} 
            key={props.index} className="team-box" 
            ref={ref} 
            onDragLeave={props.handleDragLeave} 
            draggable={props.mode === 'local' ? "true" : "false"}
            onTouchStart={(e) => props.handleDragStart('touch', e)}
            onTouchMove={props.handleTouchMove}
            onTouchEnd={(e) => props.handleDrop('touch', e)}
            onDragStart={(e) => props.handleDragStart('mouse', e)} 
            onDrag={(e) => props.drag('mouse', e)} 
            onDragOver={props.handleDragOver} 
            onDrop={(e) => props.handleDrop('mouse', e)}
        >
            <label htmlFor="teamname">Team {props.index + 1}</label>
            <input onChange={props.editTeamName} id={`teamname-${props.index}`} name="teamname" type="text" placeholder={lang[0]} value={props.name ? props.name : ''} maxLength={25}></input>
            <Button variant="secondary" onClick={props.deleteTeam}>
                <ion-icon name="close-outline"></ion-icon>
            </Button>
        </div>);
};

export default Team;
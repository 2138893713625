const language = {
  en: {
      0: `flipGET`,
      1: 'Please sign in or create an account.',
      2: 'User Email',
      3: 'User password',
      4: 'Login',
      5: 'New user email',
      6: 'New user password',
      7: 'New account',
      8: 'Cloud Saves',
      9: 'Edit',
      10: 'Play',
      11: `Hey there, `,
      12: 'Select a collection',
      13: 'Sign out',
      14: 'Back',
      15: 'Editor',
      16: 'card',
      17: `Collection: `,
      18: 'Local play',
      19: 'Join an online game',
      20: 'Host an online game',
      21: `Your game code: `,
      22: 'Team name',
      23: `Choose a card, `,
      24: `Choose your second card, `,
      25: 'Oops! Better luck next time.',
      26: `It's a match!`,
      27: `You're up, . Choose a card.`,
      28: 'Game over!',
      29: 'Welcome back!',
      30: 'shuffleAttack!',
      31: `Once you have an account, you'll see your saved collections here. Sign in or create an account to get started.`,
      32: 'Yo!',
      33: 'This will permanently delete your collection. Are you sure?',
      34: 'Delete',
      35: 'Cancel',
      36: 'Hold up a second.',
      37: 'Are you sure you want to leave the game?',
      38: 'Leave',
      39: 'Attack Menu',
      40: 'Kick Menu',
      41: 'Your collections',
      42: 'Password Change',
      43: `Type your new password. The change will take place once you click 'accept'.`,
      44: 'Accept',
      45: 'Your new password',
      46: 'One more time, please',
      47: `It seems you're trying to change sensitive information.`,
      48: 'Before you can do that, please confirm your password.',
      49: 'Join',
      50: 'Start!',
      51: 'Please enter the game ID',
      52: 'Your team name. Make it fun!',
  },

  jp: {
      0: 'flipGET',
      1: `ログインまたは新しいアカウントを作ってください。`,
      2: `メールアドレス`,
      3: `パスワード`,
      4: 'ログイン',
      5: '新しいアカウントメールアドレス',
      6: '新しいアカウントパスワード',
      7: '新しいアカウント',
      8: 'あなたのカード',
      9: '編集',
      10: 'プレイ',
      11: `よー, `,
      12: 'カードを決定してください。',
      13: 'ログアウト',
      14: '戻る',
      15: '編集',
      16: 'カード',
      17: `カード: `,
      18: 'ローカルプレイ',
      19: 'オンラインゲームを参加',
      20: 'オンラインゲームを始まる',
      21: `あなたのオンラインゲームコード: `,
      22: 'チーム',
      23: `カードを選んでください, `,
      24: `2枚目のカードを選んでください, `,
      25: '残念。ファイト！！',
      26: `ペア！ナイスゲット！`,
      27: `頑張って, 。 カードを選んでください.。`,
      28: '終了！',
      29: 'ようこそ！',
      30: 'シャフルアタック！',
      31: 'ログインまたは新しいアカウントを作ってください。',
      32: 'や！',
      33: 'カードのデータを消しますか？',
      34: '消す',
      35: 'キャンセル',
      36: '確認してください。',
      37: 'メニューに戻りますか？',
      38: '戻る',
      39: 'アタックメニュー',
      40: 'キックメニュー',
      41: 'あなたのカード',
      42: 'パスワード変更',
      43: '新しいパスワードを入力してください。',
      44: 'OK',
      45:'新しいパスワード',
      46:'もう一度',
      47: 'アカウント情報を変えようとしてるみたいです。',
      48: '変える前に、もう一度パスワードを入力してください。',
      49: '参加',
      50: 'Go!',
      51: 'ゲームID',
      52: 'チームネーム',
  }
}

export default language;
import React from 'react';
import MenuCustomCollectionItem from '../../../MenuCustomCollectionItems/MenuCustomCollectionItem/MenuCustomCollectionItem';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { CSSTransition } from 'react-transition-group';


const TopbarItem = props => {

    const team = props.team;
    const index = props.index;

    const languageStrings = {

        en: {
            0: 'shuffle!',
            1: 'skip!',
            2: 'Your cards'
        },

        jp: {
            0: 'シャッフル！',
            1: 'スキップ！',
            2: 'あなたのカード'
        }
    }

    let lang = languageStrings[props.language];

    return (
        <div key={index} id={`team-${team.id}`} className="team-wrapper">
            {props.activeID === team.id ? <div className="team-red-back"></div> : null}
            <div className='team-info'>
                <p className='team-name' title={team.name}>
                    {team.name}
                </p>
                <small>{team.id == props.localID ? '(me)' : ""}</small>
                <p className="team-points">{team.points ? team.points : 0}</p>
                {team.shouldSkip ? <div className="debuff-list">{team.shouldSkip}<ion-icon name="play-forward-circle-outline"></ion-icon></div> : null}
                {props.mode === 'local' ?
                    <button className={team.flipAttacks.skip && props.activeID === team.id ? "attack-button" : "attack-button-grey"} onClick={team.flipAttacks.skip && props.activeID === team.id ? () => props.displayAttackMenu('skip') : null}>
                        {lang[1]}
                    </button>
                    :
                    null
                }

                {props.mode !== 'local' && team.id === props.localID ? <button className={team.flipAttacks.skip ? "attack-button" : "attack-button-grey"} onClick={team.flipAttacks.skip && team.id === props.localID ? () => props.displayAttackMenu('skip') : null}>
                    {lang[1]}
                </button> : null}
                {(team.flipAttacks.shuffle && (team.id === props.localID || (props.mode === 'local' && props.activeID === team.id))) ? <button className="attack-button" onClick={() => props.displayAttackMenu('shuffle')}>
                    {lang[0]}
                </button> : null}
                {props.mode === 'host' && team.id === props.localID ? <button className="attack-button" onClick={() => props.displayAttackMenu('host-kick')}>Kick</button> : null}
                <OverlayTrigger
                    trigger="click"
                    placement={"bottom"}
                    rootClose={true}
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                        //TODO: fix exit fade
                        <CSSTransition
                            classNames="fade"
                            appear={true}
                            in={true}
                            timeout={200}
                        >
                            <Popover id="popover-basic">
                                <div className="full-menu">
                                    <p>{lang[2]}</p>
                                    <div>
                                        {team.matchedCards ?
                                            <MenuCustomCollectionItem
                                                cards={team.matchedCards}
                                            />
                                            : null}
                                    </div>
                                </div>
                            </Popover>
                        </CSSTransition>
                    }
                >
                    <ion-icon name="caret-down-outline"></ion-icon>
                </OverlayTrigger>
            </div>
        </div>
    )
}

export default TopbarItem;
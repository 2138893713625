import React from 'react';
import InfoPage from "../InfoPage/InfoPage";

const PrivacyPolicy = () => {
    return (
        <InfoPage
            title={"Privacy Policy"}
        >
            <p>
            flipGET doesn't store any information about visitors without accounts. For those with accounts, we do save the email you gave us and your collection data. While admins can see your email and saved collections, this information will not be shared with any outside parties. We also won't email you. Your password is not shared with anyone, including admins. As of November 2020, we don't verify emails so that even students that don't have emails can sign up for accounts. If you don't want to use your real email, feel free to use an email-like account name to sign up. Something like 'johnsmith@johnsmith.com' or 'iamcool@iam.cool' will work. flipGET uses Google's Firebase authorization system to identify and verify users. If you'd like to read about their system, please check out <a href="https://firebase.google.com/docs/auth/">https://firebase.google.com/docs/auth/</a>.
            </p>
        </InfoPage>
    )
}

export default PrivacyPolicy;
import React, { useEffect, useCallback } from "react";
import { withRouter } from 'react-router-dom';

import "./UserInfo.css";
import LoadingIndicator from "../../../hoc/LoadingIndicator";

const UserInfo = (props) => {
  const creatingNewAccount = props.creatingNewAccount;
  const createNewUser = props.createNewUser;
  const setCurrentUser = props.setCurrentUser;

  const handleEnterKey = useCallback(e => {
    if (e.keyCode !== 13) return;
    return creatingNewAccount ? createNewUser() : setCurrentUser();
  }, [creatingNewAccount, createNewUser, setCurrentUser]);

  useEffect(() => {
    const info = document.querySelector('.user-info');
    if (info) info.addEventListener('keypress', handleEnterKey);

    return function cleanup() {
      info.removeEventListener('keypress', handleEnterKey);
    };
  }, [handleEnterKey]);

  const languageStrings = {
    en: {
      0: "Please sign in",
      1: "User Email",
      2: "User password",
      3: "Login",
      4: "New user email",
      5: "New user password",
      6: "New account",
      7: `Hey there, ${props.user ? props.user.displayName : ""}`,
      8: "Select a collection",
      9: "Sign out",
      10: `Collection: ${props.collectionTitle}`,
      11: `Need a new account?`,
      12: `I have an account`,
      13: "Create an account",
      14: "Change my password",
    },

    jp: {
      0: `ログインしてください。`,
      1: `メールアドレス`,
      2: `パスワード`,
      3: "ログイン",
      4: "新しいアカウントメールアドレス",
      5: "新しいアカウントパスワード",
      6: "新しいアカウント",
      7: `よー, ${props.user ? props.user.displayName : ""}!`,
      8: "カードを決定してください。",
      9: "ログアウト",
      10: `カード: ${props.collectionTitle}`,
      11: `新しいアカウント`,
      12: `ログイン`,
      13: `新しいアカウントを作ってください。`,
      14: "パスワードを変更したい",
    },
  };

  let lang = languageStrings[props.language];

  return (
    <div className={"user-info"}>
      {props.user ?
        <>
          {props.loading ?
            <LoadingIndicator />
            :
            <>
              <p>{props.user.displayName}</p>
              <button
                className="alt"
                onClick={() => props.displayModal("pass-change")}
              >
                {lang[14]}
              </button>
              <button
                className="alt"
                onClick={() => props.displayModal('delete-account')}
              >
                Delete my account
              </button>
              <button className="userInfoButton" onClick={props.signUserOut}>
                {lang[9]}
              </button>
            </>}
        </>
      :
        <>
              {props.loading ?
                <LoadingIndicator />
                :
                <button
                  key={7}
                  onClick={() => props.displayModal('login')}
                >
                  {lang[3]}
                </button>
              }
              <button
                key={8}
                className="alt"
                onClick={() => props.displayModal('new-account')}
              >
                {lang[11]}
              </button>
            </>}
    </div>
  );
};

export default withRouter(UserInfo);

import React from 'react';
import InfoPage from '../InfoPage/InfoPage';

const About = () => {
    return (
        <InfoPage
            title={"About"}        
        >
            <p>
            Welcome to flipGET! <br></br>
                    My name is Colin and I was formerly an English teacher in Japan. flipGET is a project that I and some friends started during the 2020 COVID-19 school closures. The game was been a big hit with my students. Please feel free to use it in your own classes. If you want to learn about future updates and plans, follow along at <a href="https://github.com/tkopdx/concentration">https://github.com/tkopdx/concentration</a>.
            </p>
        </InfoPage>
    )
}

export default About;
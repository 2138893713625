import React from "react";
import styles from "./Updates.module.css";
import Update from './Update/Update';
import { updates as updateText } from "../../functions/updatesText";
import InfoPage from "../InfoPage/InfoPage";

const updates = props => {
    return (
        <InfoPage 
            title={"Updates"}
        >
            <ul className={styles.updateList}>
                {updateText().map((update, index) => {
                    return <Update
                                key={index}
                                date={update.date}
                                items={update.items}
                            />
                })
                }
            </ul>
        </InfoPage>
    )
}

export default updates;
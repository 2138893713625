import React from 'react';
import { Pagination } from 'react-bootstrap';

import './Pagination.css';

const PaginationFooter = props => {
    let active = props.active;
    let items = [];
    for (let number = 1; number <= props.pages; number++) {
    items.push(
        <Pagination.Item key={number} active={number === active} onClick={() => props.clicked(number)}>
        {number}
        </Pagination.Item>,
    );
    }

    const paginationBasic = (
    <div className="pagination-footer-box">
        <Pagination>{items}</Pagination>
    </div>
    );

    return(paginationBasic);
}

export default PaginationFooter;